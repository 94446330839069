import Bloc from './Bloc.js';
import Types from './Types.js';

export default class ForEachCard extends Bloc {

	constructor (src, ctx) {

		super("forcard", src, ctx, true);
		this.f = (src, ins, props) => {
			/*let lock = !src.game.broadcaster.locked;
			if (lock)
				src.game.broadcaster.lock();*/
			var area = ins[0], targets = ins[1];
			var cards = area.cards;
			cards.forEach(card => {
				this.out = [card];
				if (this["for each"])
					this["for each"].execute(props);
			})
			/*if (lock)
				src.game.broadcaster.unlock();*/
			this.out = null;
			if (this.completed)
				this.completed.execute(props);
			return;
		}
		this.types = [Types.location, Types.cardfilter];
		this.toPrepare.push("for each");
		this.toPrepare.push("completed");
	}
}