
export default class DiscardingState {

	constructor (scene, fight) {

		this.scene = scene;
		this.fight = fight;
		this.name = "handchoice";
	}

	selectCard (card) {

		let model = this.fight.find(card.id);
		this.fight.chooseHand(model);
	}
}