import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Dissipate extends Bloc {

	constructor (src, ctx) {

		super("dissipate", src, ctx, true);
		this.f = (src, ins) => {
			src.dissipate();
			return [];
		};
		this.types = [];
	}
}