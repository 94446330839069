import static_cards from '../../library/cards.json';
import static_champions from '../../library/champions.json';
import static_rooms from '../../library/rooms.json';
import static_casual_enemies from '../../library/casual_enemies.json';
import static_challenge_enemies from '../../library/challenge_enemies.json';
import static_statuses from '../../library/statuses.json';
import static_relics from '../../library/relics.json';

const DYNAMIC_LIBRARY = false;

let cards = DYNAMIC_LIBRARY ? JSON.parse(localStorage.getItem('library.cards')) : static_cards;
let champions = DYNAMIC_LIBRARY ? JSON.parse(localStorage.getItem('library.champions')) : static_champions;
let rooms = DYNAMIC_LIBRARY ? JSON.parse(localStorage.getItem('library.rooms')) : static_rooms;
let casualenemies = DYNAMIC_LIBRARY ? JSON.parse(localStorage.getItem('library.casualenemies')) : static_casual_enemies;
let challengeenemies = DYNAMIC_LIBRARY ? JSON.parse(localStorage.getItem('library.challengeenemies')) : static_challenge_enemies;
let statuses = DYNAMIC_LIBRARY ? JSON.parse(localStorage.getItem('library.statuses')) : static_statuses;
let relics = DYNAMIC_LIBRARY ? JSON.parse(localStorage.getItem('library.relics')) : static_relics;

let cardsversion = localStorage.getItem('library.cards.version');
let championsversion = localStorage.getItem('library.champions.version');
let roomsversion = localStorage.getItem('library.rooms.version');
let casualenemiesversion = localStorage.getItem('library.casualenemies.version');
let challengeenemiesversion = localStorage.getItem('library.challengeenemies.version');
let statusesversion = localStorage.getItem('library.statuses.version');
let relicsversion = localStorage.getItem('library.relics.version');

export default class Library {

	static get cards () {

		if (DYNAMIC_LIBRARY && localStorage.getItem('library.cards.version') !== cardsversion) {
			cards = JSON.parse(localStorage.getItem('library.cards'));
			cardsversion = localStorage.getItem('library.cards.version');
		}
		return cards;
	}

	static get champions () {
		
		if (DYNAMIC_LIBRARY && localStorage.getItem('library.champions.version') !== championsversion) {
			champions = JSON.parse(localStorage.getItem('library.champions'));
			championsversion = localStorage.getItem('library.champions.version');
		}
		return champions;
	}

	static get rooms () {
		
		if (DYNAMIC_LIBRARY && localStorage.getItem('library.rooms.version') !== roomsversion) {
			rooms = JSON.parse(localStorage.getItem('library.rooms'));
			roomsversion = localStorage.getItem('library.rooms.version');
		}
		return rooms;
	}

	static get challengeEnemies () {
		
		if (DYNAMIC_LIBRARY && localStorage.getItem('library.challengeenemies.version') !== challengeenemiesversion) {
			challengeenemies = JSON.parse(localStorage.getItem('library.challengeenemies'));
			challengeenemiesversion = localStorage.getItem('library.challengeenemies.version');
		}
		return challengeenemies;
	}

	static get casualEnemies () {
		
		if (DYNAMIC_LIBRARY && localStorage.getItem('library.casualenemies.version') !== casualenemiesversion) {
			casualenemies = JSON.parse(localStorage.getItem('library.casualenemies'));
			casualenemiesversion = localStorage.getItem('library.casualenemies.version');
		}
		return casualenemies;
	}

	static get statuses () {
		
		if (DYNAMIC_LIBRARY && localStorage.getItem('library.statuses.version') !== statusesversion) {
			statuses = JSON.parse(localStorage.getItem('library.statuses'));
			statusesversion = localStorage.getItem('library.statuses.version');
		}
		return statuses;
	}

	static get relics () {
		
		if (DYNAMIC_LIBRARY && localStorage.getItem('library.relics.version') !== relicsversion) {
			relics = JSON.parse(localStorage.getItem('library.relics'));
			relicsversion = localStorage.getItem('library.relics.version');
		}
		return relics;
	}

	static getCard(key) {

		if (DYNAMIC_LIBRARY && localStorage.getItem('library.cards.version') !== cardsversion) {
			cards = JSON.parse(localStorage.getItem('library.cards'));
			cardsversion = localStorage.getItem('library.cards.version');
		}
		return cards[key];
	}

	static getChampion(key) {

		if (DYNAMIC_LIBRARY && localStorage.getItem('library.champions.version') !== championsversion) {
			champions = JSON.parse(localStorage.getItem('library.champions'));
			championsversion = localStorage.getItem('library.champions.version');
		}
		return champions[key];
	}

	static getRoom(key) {

		if (DYNAMIC_LIBRARY && localStorage.getItem('library.rooms.version') !== roomsversion) {
			rooms = JSON.parse(localStorage.getItem('library.rooms'));
			roomsversion = localStorage.getItem('library.rooms.version');
		}
		return rooms[key];
	}

	static getEnemy(key, difficulty="challenge") {

		/*if (DYNAMIC_LIBRARY && localStorage.getItem('library.enemies.version') !== enemiesversion) {
			enemies = JSON.parse(localStorage.getItem('library.enemies'));
			enemiesversion = localStorage.getItem('library.enemies.version');
		}*/
		return difficulty === "casual" ? casualenemies[key] : challengeenemies[key];
	}

	static getStatus(key) {

		if (DYNAMIC_LIBRARY && localStorage.getItem('library.statuses.version') !== statusesversion) {
			statuses = JSON.parse(localStorage.getItem('library.statuses'));
			statusesversion = localStorage.getItem('library.statuses.version');
		}
		return statuses[key];
	}

	static getRelic(key) {

		if (DYNAMIC_LIBRARY && localStorage.getItem('library.relics.version') !== relicsversion) {
			relics = JSON.parse(localStorage.getItem('library.relics'));
			relicsversion = localStorage.getItem('library.relics.version');
		}
		return relics[key];
	}
}