import Library from './scene/utility/Library';
import { saveAs } from 'file-saver';
import { updateSaveData } from './VersionUpdater';
import CryptoJS from 'crypto-js';

const version = '0.1';

const champions = Library.champions;

let premium = false;

let items = { version }

const difficultyOptions = ["casual", "challenge"];
let difficulty = localStorage.getItem("difficulty");

if (!difficulty || !difficultyOptions.includes(difficulty)) {
	difficulty = "challenge";
	localStorage.setItem("difficulty", "challenge");
}

if (localStorage.getItem("user.save")) {

	let storage = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("user.save"), process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));
	if(storage.progress)
		updateSaveData(storage);
	items = Object.assign({}, storage, { version })
}

export default class User {

	static updateStorage () {

		localStorage.setItem("user.save", CryptoJS.AES.encrypt(JSON.stringify(items), process.env.REACT_APP_ENCRYPTION_KEY).toString());
	}

	static setItem (item, value) {

		items[item] = value;
		User.updateStorage();
	}

	static get difficulty () {

		return difficulty;
	}

	static set difficulty (value) {

		difficulty = value;
		localStorage.setItem("difficulty", value);
	}

	static getItem (item) {

		return items[item];
	}

	static get currentRun () {

		let run = items["run.save"];
		if (run) {
			run = JSON.parse(run);
			if (premium)
				return run;
			let runmodel = Library.getChampion(run.champion.model);
			let runpremium = runmodel.premium;
			if (runpremium)
				return;
			return run;
		}
	}

	static get championSelect () {

		let select = items["champion.select"];
		if (select) {
			if (premium)
				return select;
			let runmodel = Library.getChampion(select);
			let runpremium = runmodel.premium;
			if (runpremium)
				return 1;
			return select;
		}
		return 1;
	}

	static removeItem (item) {

		delete items[item];
		User.updateStorage();
	}

	static get progress () {

		return progress;
	}

	static unlockPremium () {

		premium = true;
	}

	static get premium () {

		return premium;
	}

	static save () {

		/*const saveData = JSON.stringify({
		    progress: localStorage.getItem('progress') || '',
		    gallery: localStorage.getItem('gallery') || '',
		    championSelect: localStorage.getItem('champion.select') || '',
		    language: localStorage.getItem('language') || '',
		    runLog: localStorage.getItem('run.log') || '',
		    runSave: localStorage.getItem('run.save') || ''
		});*/
    	const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(items), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    	const blob = new Blob([encryptedData], { type: 'text/plain;charset=utf-8' });
    	saveAs(blob, 'mazeofambition.save');
	}

	static load (file) {

	    if (file) {
	      const reader = new FileReader();
	      reader.onload = (e) => {
	        const encryptedData = e.target.result;
	        try {
	          const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY);
	          const originalData = bytes.toString(CryptoJS.enc.Utf8);
	          //localStorage.setItem('save', originalData);
	          const data = JSON.parse(originalData);;
	          items = Object.assign({}, data, { version });
	          User.updateStorage();
	          console.log('Data loaded');
	          window.history.pushState(null, null, '/play')
	          window.location.reload(false);
	        } catch (error) {
	          console.error('Error decrypting file:', error);
	        }
	      };
	      reader.readAsText(file);
	    }
	}

	static clear () {

		items = { version };
		User.updateStorage();
		console.log('Data cleared');
		window.history.pushState(null, null, '/play')
		window.location.reload(false);
	}

	static advance (type, champion) {console.log(JSON.stringify(progress))

		progress[champion][type]++;
		User.setItem('progress', JSON.stringify(progress));
	}

	static getQuest (champion, run) {

		let p = progress[champion];
		if (run.storyEvent)
			return;
		if (p.level === 1)
			return  { text: "Clear the first floor " + (run.difficultySetting === "casual" ? "(1-12)." : "(1-16).") };
		else if (p.level === 2) {
			switch (champion) {
			case 1:
				if (p.story >= 1)
					return;
				if (run.champion.level >= 2)
					return { text: "Fight Petra now!", active: true, callback: () => {
						run.startStoryEvent([1001], "event1", "amber");
					} }
				return { text: "Reach level 2 and fight Petra" };
			case 2:
				if (p.story >= 1)
					return;
				if (run.champion.level >= 2)
					return { text: "Meet Jenny now!", active: true, callback: () => {
						run.startStoryEvent([2001], "love", "ruby");
					} }
				return { text: "Reach level 2 and meet Jenny" };
			case 3:
				if (p.story >= 1)
					return;
				if (run.champion.level >= 2)
					return { text: "Attend the interview now!", active: true, callback: () => {
						run.startStoryEvent([3001], "event2", "pearl");
					} }
				return { text: "Reach level 2 and attend the interview" };
			default: break;
			}
		}
	}

	static hasSeen (cutscene) {

		return gallery.includes(cutscene);
	}

	static watch (cutscene) {

		if (this.hasSeen(cutscene))
			return;
		gallery.push(cutscene);
		User.setItem('gallery', JSON.stringify(gallery));
	}
}

let progress = User.getItem('progress') ? JSON.parse(User.getItem('progress')) : {}
let gallery = User.getItem('gallery') ? JSON.parse(User.getItem('gallery')) : []

Object.values(champions).forEach(c => {
	if (!progress[c.key])
		progress[c.key] = {
          level: 1,
          story: 0
        }
})