import Library from '../utility/Library';
import Entity from './Entity';
import Status from './Status';
import Reader from './blueprint/Reader';

export default class Player extends Entity {

	constructor (master) {

		super();
		this.master = master;
		this.champion = master.run.champion;
	}

	init () {

		if (this.champion.model.passive)
			Reader.read(this.champion.model.passive.blueprint, this);
		this.champion.relics.forEach(relic => {
			if (relic.blueprint)
				Reader.read(relic.blueprint, this);
		});
	}

	get hp () { return this.champion.hp }
	get maxhp () { return this.champion.maxhp }

	get img () { return this.champion.model.img[this.champion.level-1] }

	get id () {

		return "player";
	}

	get model () {

		return this.champion.model;
	}

	heal (amt) {

		this.champion.heal(amt);
	}

	loseLife (amt, src) {

		this.champion.loseLife(amt);
	}

	die () {

		super.die();
		this.master.lose();
	}

	serialize () {

		return {
			key: this.key,
			block: this.block,
			statuses: this.statuses.map(status => status.serialize())
		}
	}

	static build (master, src, init) {

		let player = new Player(master);
		if (init)
			player.init();
		player.block = src.block;
		player.statuses = src.statuses.map(status => Status.build(master, player, status));
		return player;
	}
}