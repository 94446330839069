

export default class Broadcaster {

	log = [];
	subscriptions = {};
	notifications = [];
	indexSubscription = 0;

	subscribe (type, notify) {

		if (!this.subscriptions[type])
			this.subscriptions[type] = [];
		let id = this.indexSubscription++;
		this.subscriptions[type].push({ id, notify });
		return () => this.subscriptions[type].splice(this.subscriptions[type].findIndex(sub => sub.id === id), 1);
	}

	start (manager) {

		this.manager = manager;
		this.live = true;
	}

	stop () {

		this.live = false;
	}

	translateData (obj) {

		if (!obj)
		    return obj;
		if (typeof obj !== "object")
			return obj;
		if (obj.id)
			return obj.id;
		return "object";
	}

	addLog (log) {

		if (log.type === "gamestate")
			return;
		this.log.push({ type: log.type, data: log.data.map(d => this.translateData(d)) })
	}

	notify (type, data) {
		
		if (type === "error") {
			this.manager.notify(type, data);
			return;
		}

		let unfiltered = this.filter(type);
		if (unfiltered)
			this.addLog({ type, data });
		if (this.manager /*&& unfiltered*/)
			this.manager.notify(type, data);
		if (this.subscriptions[type])
			this.subscriptions[type].slice().forEach(sub => sub.notify(type, data));
	}

	filter (type) {

		switch (type) {
		default: return !type.includes(".");
		}
	}
}