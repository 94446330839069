
export default class PlayingState {

	constructor (scene, fight) {

		this.scene = scene;
		this.fight = fight;
		this.name = "playing";
	}

	selectCard (card) {

		let model = this.fight.find(card.id);
		if (!model.canBePlayed)
		  return;
		if (model.hasTarget)
		  this.selected = model;
		else if (this.selected)
			delete this.selected;
		else
		  model.play();
	}

	selectEntity (entity) {

		if (this.selected)
		  if (this.selected.canBePlayed && this.selected.canTarget(this.fight.find(entity.id))) {
		    this.selected.play(this.fight.find(entity.id));
		    delete this.selected;
		  }
	}

  endTurn () {

    this.fight.endPlayerTurn();
  }

  cardHalo (card) {

		let model = this.fight.find(card.id);
		if (this.selected && model === this.selected)
			return "selected-halo";
		if (model.canBePlayed)
		  return "active-halo";
		return;
  }

  unselect () {

  	delete this.selected;
  }
}