import Player from './Player';
import Enemy from './Enemy';
import Deck from './Deck';
import Hand from './Hand';
import Court from './Court';
import Discard from './Discard';
import Exile from './Exile';
import Card from './Card';
import Library from '../utility/Library';

const BASE_MANA = 3;
const HAND_SIZE = 6;

export default class Fight {

	data = {};
	ids = {};

	turnmana = BASE_MANA;
	mana = 0;
	turncount = 0;

	constructor (run) {

		this.run = run;
		this.enemies = [];
		this.loot = [];
		this.player = new Player(this);
		this.deck = new Deck(this);
		this.hand = new Hand(this);
		this.court = new Court(this);
		this.discard = new Discard(this);
		this.exile = new Exile(this);
		this.notify('newfight', this, run);
	}

	get liveEnemies () {

		return this.enemies.filter(enemy => enemy.alive);
	}

	start (enemies) {

		this.player.init();
		this.enemies = enemies.map(enemy => new Enemy(this, enemy));
		this.deck.init(this.run.deck);
		this.notify('startfight', this);
		this.enemies.forEach(enemy => enemy.init());
		this.playerTurn();
	}

	playerTurn () {

		this.turn = "player";
		this.turncount++;
		this.mana = this.turnmana;
		this.enemies.forEach(enemy => enemy.haveIntent());
		this.discard.cards.forEach(card => card.goto(this.deck));
		this.deck.shuffle();
		this.notify('playerturn', this);
		this.player.update();

		let handSize = HAND_SIZE;
		if (this.player.statuses)
			this.player.statuses.forEach(status => {
				if (!status.model.modifiers)
					return;
				status.model.modifiers.filter(modifier => modifier.stat === "handsize").forEach(modifier => handSize += modifier.value * status.value);
			})
		handSize = Math.min(Math.max(0, handSize), handSize);

		if (this.hand.count < handSize)
			this.draw(handSize - this.hand.count);
	}

	endPlayerTurn () {

		this.player.endturnUpdate();
		this.hand.cards.forEach(card => {
			if (card.volatile)
				card.discard();
			if (card.properties.locked)
				card.removeProperty("locked");
		})
		this.notify('endplayerturn', this);
		this.enemyTurn();
	}

	enemyTurn () {

		this.turn = "enemy";
		this.liveEnemies.forEach(enemy => enemy.update());
		this.notify('enemyturn', this);
		this.liveEnemies.forEach(enemy => enemy.act());
		this.endEnemyTurn();
	}

	endEnemyTurn () {

		this.liveEnemies.forEach(enemy => enemy.endturnUpdate());
		this.notify('endenemyturn', this);
		this.playerTurn();
	}

	draw (n=1, filter) {

		if (this.hand.isFull)
			return;
		let card = this.deck.draw(filter);
		if (!card)
			return;
		if (this.hand.isFull) {
			//this.game.notify("burn.before", this, card);
			//card.banish();
			//card.discard();
			//this.game.notify("burn", this, card);
		}
		else {
			this.run.notify("draw.before", this, card);
			this.hand.addCard(card);
			if (card.autocast) {
				card.play(null, false);
				this.draw();
			}
			this.run.notify("draw", this, card);
		}
		if (n > 1)
			this.draw(n-1, filter);
		else return card;
	}

	addMana (mana) {

		this.mana += mana;
		this.notify('addmana', this, mana);
	}

	pay (mana) {

		this.mana -= mana;
		this.notify('paymana', this, mana);
	}

	generateGoldReward (depth) {

		let gold = (1 + depth) * 4;
		gold += Math.floor(gold * 0.3 * Math.random());
		gold += Math.floor(Math.random() * 5);
		return gold;
	}

	lootRelic (difficulty) {

		let relics = Library.relics;
		relics = Object.values(relics).filter(relic => {
			switch (difficulty) {
			case "boss":
				if (relic.rarity !== "boss")
					return false;
				break;
			case "elite":
				if (relic.rarity !== "common")
					return false;
				break;
			}
			if (relic.champion && relic.champion !== this.run.champion.model.key)
				return false;
			if (relic.floors && (this.run.depth < relic.floors[0] || this.run.depth > relic.floors[1]))
				return false;
			if (this.run.champion.relics.some(r => r.key === relic.key))
				return false;
			return true;
		});

		let relic = relics[Math.floor(Math.random() * relics.length)];
		return relic;
	}

	win () {

		if (this.done)
			return;

		this.run.roomsCleared += 1;
		if (this.run.difficulty === "boss" || this.run.storyEvent)
			this.run.bossesSlain += 1;
		this.notify("winfight", this);

		this.cleanup();

		if (this.run.floor.length >= (this.run.casual ? 12 : 16) && this.run.depth >= this.run.maxFloor || this.run.storyEvent) {
			this.run.victory();
			return;
		}

		let difficulty = this.run.difficulty;
		let expmult = 1;
		let goldmult = 1;
		switch (difficulty) {
		case "boss": /*his.addLoot({type: "relic", relic: this.lootRelic(difficulty)});*/ expmult = 3; goldmult = 3; break;
		case "elite": /*this.addLoot({type: "relic", relic: this.lootRelic(difficulty)});*/ expmult = 2; goldmult = 2; break;
		default: break;
		}
		if (this.run.champion.level < this.run.champion.maxLevel)
			this.addLoot({type: "exp", value: this.run.depth * expmult});
		//this.run.champion.gainExp(this.run.depth);
		/*if (Math.random() < this.run.cardChance) {
			this.addLoot({type: "card"});
			this.run.cardChance = 0;
		} else {
		*/	this.addLoot({type: "gold", value: goldmult * this.generateGoldReward(this.run.depth)});
		/*	this.run.cardChance += 0.5 * Math.pow(0.95, this.run.depth-1);
		}*/
		this.reward();

		let loot = []
		while (this.loot.length > 0) {
			loot.push(this.loot[0])
			this.collect(0);
		}
		loot.forEach(l => this.addLoot(l));
	}

	lose () {

		if (this.done)
			return;

		this.cleanup();
		this.run.gameOver();
	}

	cleanup () {

		this.done = true;
		this.player?.passives?.forEach(p => p());
		if (this.data.card)
			Object.values(this.data.card).forEach(c => {
				if (c.passives)
					c.passives.forEach(p => p())
			});
		this.player.statuses.forEach(status => status.dissipate());
		this.enemies.forEach(enemy => enemy.statuses.forEach(status => status.dissipate()));
	}

	reward () {

		this.rewarding = true;
		this.notify('fightreward', this);
	}

	addLoot (loot) {

		let gold = this.loot.filter(l => l.type === "gold").length > 0 ? this.loot.filter(l => l.type === "gold")[0] : null;
		if (gold && loot.type === "gold")
			gold.value += loot.value;
		else this.loot.push(loot);
		this.notify('fightloot', this, loot);
	}

	collect (n) {

		let loot = this.loot[n];
		switch (loot.type) {
		case "card":
			this.run.cardChoice();
			break;
		case "gold":
			this.run.champion.gainGold(loot.value);
			break;
		case "exp":
			this.run.champion.gainExp(loot.value);
			break;
		case "relic":
			this.run.champion.newRelic(loot.relic);
			break;
		default: break;
		}

		this.loot.splice(n, 1);
		this.notify('collectloot', this, n);
	}

	cardChoice () {

		this.choosingCard = true;

		let cards = Object.values(Library.cards);
		cards = cards.filter(c => c.champion === this.run.champion.model.key && c.level > 0 && c.level > this.run.champion.level - 3 && c.level <= this.run.champion.level);
		let cardlevels = Array.from({ length: this.run.champion.level }, (_, i) => cards.filter(c => c.level === i + 1));
		let lv1 = cardlevels.length > 3 ? cardlevels[cardlevels.length-3] : cardlevels[0];
		let lv2 = cardlevels.length > 2 ? cardlevels[cardlevels.length-2] : cardlevels[0];
		let lv3 = cardlevels[cardlevels.length-1];
		let options = [];
		for (let i = 0; i < 3; i++) {
			let option = null;
			let r = Math.random();
			let lv = r < 0.4 ? lv3 : (r < 0.7333 ? lv2 : lv1);
			while (option === null || options.includes(option))
				option = lv[Math.floor(Math.random() * lv.length)];
			options.push(option);
		}

		this.options = options;
		this.notify('lootcardchoice', this, options);
	}

	collectCard (n) {

		if (!this.options || !this.choosingCard)
			return;

		this.run.addCard(this.options[n]);

		delete this.choosingCard;
		delete this.options;
		this.notify('collectcard', this, n);
	}

	handChoice (callback) {

		if (this.done)
			return;
		this.choosingHand = callback;
		this.notify('handchoice', this);
	}

	chooseHand (card) {

		if (this.choosingHand && this.hand.cards.includes(card)) {
			this.notify('chosehand', this);
			let ch = this.choosingHand;
			this.choosingHand(card);
			if (ch === this.choosingHand)
				delete this.choosingHand;
		}
	}

	notify (type, ...data) {

		if (this.run)
			this.run.notify(type, ...data);
	}

	subscribe (type, notify) {

		if (this.run)
			return this.run.subscribe(type, notify);
		return () => {};
	}

	register (item, type, givenno) {

		item.master = this;
		this.data[type] = this.data[type] || {};
		this.ids[type] = this.ids[type] || 0;
		let no = givenno === undefined ? this.ids[type]++ : givenno;
		item.id = {type, no};
		this.data[type][no] = item;
	}

	unregister (item) {

		let id = item.id;
		if (!id || !this.data[id.type] || !this.data[id.type][id.no])
			return;
		delete this.data[id.type][id.no];
		if (Object.keys(this.data[id.type]).length === 0)
			delete this.data[id.type];
		delete item.id;
	}

	find (id) {

		switch (id) {
		case "champion": return this.run.champion;
		case "player": return this.player;
		case "deck": return this.deck;
		case "hand": return this.hand;
		case "discard": return this.discard;
		case "exile": return this.exile;
		default: return id && this.data[id.type] ? this.data[id.type][id.no] : undefined;
		}
	}

	serialize () {

		return {
			card: this.data.card ? Object.values(this.data.card).map(c => c.serialize()) : [],
			enemy: this.data.enemy ? Object.values(this.data.enemy).map(e => e.serialize()) : [],
			enemies: this.enemies.map(e => e.key),
			player: this.player.serialize(),
			loot: this.loot.map(l => Object.assign({}, l)),
			deck: this.deck.cards.map(c => c.key),
			hand: this.hand.cards.map(c => c.key),
			court: this.court.cards.map(c => c.key),
			discard: this.discard.cards.map(c => c.key),
			exile: this.exile.cards.map(c => c.key),
			rewarding: this.rewarding,
			choosingCard: this.choosingCard,
			options: this.options ? this.options.map(option => option.key) : null,
			turn: this.turn,
			turncount: this.turncount,
			mana: this.mana,
			turnmana: this.turnmana,
			done: this.done
		}
	}

	static build (run, src) {

		let fight = new Fight(run);
		fight.done = src.done;
		if (!src.done) {
			if (src.card) {
				fight.data.card = {};
				src.card.forEach(card => fight.data.card[card.key] = Card.build(fight, card));
			}
			if (src.enemy) {
				fight.data.enemy = {};
				src.enemy.forEach(enemy => fight.data.enemy[enemy.key] = Enemy.build(fight, enemy));
			}
			fight.enemies = src.enemies.map(e => fight.find({type: "enemy", no: e}));
			fight.deck.cards = src.deck.map(c => fight.find({type: "card", no: c}));
			fight.deck.cards.forEach(c => c.location = fight.deck);
			fight.hand.cards = src.hand.map(c => fight.find({type: "card", no: c}));
			fight.hand.cards.forEach(c => c.location = fight.hand);
			fight.court.cards = src.court.map(c => fight.find({type: "card", no: c}));
			fight.court.cards.forEach(c => c.location = fight.court);
			fight.discard.cards = src.discard.map(c => fight.find({type: "card", no: c}));
			fight.discard.cards.forEach(c => c.location = fight.discard);
			fight.exile.cards = src.exile.map(c => fight.find({type: "card", no: c}));
			fight.exile.cards.forEach(c => c.location = fight.exile);
		}
		fight.loot = src.loot.map(l => Object.assign({}, l));
		fight.player = Player.build(fight, src.player, !src.done);
		fight.rewarding = src.rewarding;
		fight.choosingCard = src.choosingCard;
		fight.turn = src.turn;
		fight.turncount = src.turncount;
		fight.mana = src.mana;
		fight.turnmana = src.turnmana;
		if (src.options)
			fight.options = src.options.map(option => Library.getCard(option));
		return fight;
	}
}