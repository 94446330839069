import Library from '../utility/Library';
import Reader from './blueprint/Reader';

export default class Card {

	constructor (master, model, no) {

		master.register(this, "card", no);
		this.model = model;
		this.mana = this.model.mana;
		this.properties = [];
		this.variables = {};
		this.permanentVariables = {};

		if (this.model.blueprint)
			Reader.read(this.model.blueprint, this);

		master.notify('newcard', this, master, model);
	}

	get key () { return this.id.no; }
	get type () { return this.model.type; }
	get level () { return this.model.level; }

	get autocast () { return this.properties.includes("autocast") || this.model.autocast }
	get volatile () { return this.properties.includes("volatile") || this.model.volatile }
	get ethereal () { return this.properties.includes("ethereal") || this.model.ethereal }
	get locked () { return this.properties.includes("locked") || this.model.locked }

	reset () {

		this.mana = this.model.mana;
		this.properties = [];
		this.variables = {};
		delete this.extratriggers;
	}

	get cost () {

		let cost = this.mana;
		if (this.master.player.statuses)
			this.master.player.statuses.forEach(status => {
				if (!status.model.modifiers)
					return;
				status.model.modifiers.filter(modifier => modifier.stat === "mana" || modifier.stat === this.type).forEach(modifier => cost += modifier.value * status.value);
			})
		return cost;
	}

	goto (location) {

		if (this.ethereal && location === this.master.discard)
			location = this.master.exile;

		if (this.location === location)
			return;

		let former = this.location;
		this.master.notify("movecard.before", this, former, location);

		this.location = location;
		if (former && former.hasCard(this))
			former.removeCard(this);
		if (location === this.master.deck)
			this.reset();
		if (location && !location.hasCard(this))
			location.addCard(this);

		this.master.notify("movecard", this, former, location);
	}

	get canBePlayed () {

		if (this.location !== this.master.hand)
			return false;
		if (this.cost > this.master.mana)
			return false;
		if (this.master.choosingHand)
			return false;
		if (this.locked)
			return false;
		return true;
	}

	play (target, pay=true) {

		this.goto(this.master.court);
		if (pay)
			this.master.pay(this.cost);
		this.master.notify("cast.before", this, target, this.autocast);
		if (this.events) {
			for (let i = 1; i <= (this.extratriggers || 0); i++) {
				let tmpac = this.properties.autocast;
				if (!tmpac)
					this.properties.push("autocast");
				if (this.canTarget(target))
					this.events.forEach(e => e(target));
				if (!tmpac)
					this.properties = this.properties.filter(p => p !== "autocast");
			}
			if (this.canTarget(target))
				this.events.forEach(e => e(target));
		}
		this.master.notify("cast", this, target, this.autocast);
		if (this.location === this.master.court)
			this.goto(this.master.discard);
	}

	canTarget (target) {

		if (this.hasTarget && !target)
			return false;
		if (target && target.hp <= 0)
			return false;
		if (target && target.hasStatus(33))
			return false;
		return true;
	}

	get targets () {

		var targets = [];
		this.events.forEach(e => {
			if (e.requirement) 
				targets.push(e.requirement);
		});
		return targets;
	}

	setVariable (name, value, permanent=false) {

		if (permanent) {
			this.permanentVariables[name] = value;
			this.master.notify('setpvariable', this, name, value);
			return;
		}
		this.variables[name] = value;
	}

	getVariable (name) {

		return this.variables[name] || this.permanentVariables[name];
	}

	changeMana (value) {

		if (value === this.mana)
			return;
		this.mana = value;
		this.master.notify("changemana", this, value);
	}

	shiftMana (value) {

		if (value === 0 || (value < 0 && this.mana === 0))
			return;
		this.changeMana(Math.max(this.mana + value, 0));
	}

	addProperty (prop) {

		this.properties.push(prop);
		this.master.notify("addproperty", this, prop);
	}

	removeProperty (prop) {

		this.properties = this.properties.filter(p => p !== prop);
		this.master.notify("removeproperty", this, prop);
	}

	discard () {

		this.goto(this.master.discard);
		this.master.notify("discard", this);
	}

	exile () {

		this.goto(this.master.exile);
		this.master.notify("exile", this);
	}

	serialize () {

		return {
			key: this.key,
			model: this.model,
			mana: this.mana,
			properties: this.properties,
			extratriggers: this.extratriggers,
			permanentVariables: this.permanentVariables
		}
	}

	static build (master, src) {

		let card = new Card(master, src.model);
		card.mana = src.mana;
		card.properties = src.properties;
		card.extratriggers = src.extratriggers;
		card.permanentVariables = src.permanentVariables;
		return card;
	}
}