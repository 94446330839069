import React, { Component } from 'react';
import Flowers from '../other/flowers/Flowers';
import StoryText from '../text/StoryText';
import MainButton from '../buttons/MainButton';
import './AgeCheck.css';

export default class AgeCheck extends Component {

  state = { status: "question" }

  render () {

    return (
      <div className="main-page light agecheck-page">
        <Flowers/>
        {
          this.state.status === "question" ?
          <div className="main">
            <StoryText>This website contains age-restricted materials. Are you 18 or above?</StoryText>
            <div className="age-check-buttons">
              <MainButton onClick={() => this.props.onCheck()}>Yes</MainButton>
              <MainButton onClick={() => this.setState({status: "refusal"})}>No</MainButton>
            </div>
          </div> :
          <div className="main">
            <StoryText>This website contains age-restricted materials. You cannot continue under the age of 18.</StoryText>
          </div>
        }
      </div>
    );
  }
}

