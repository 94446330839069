import Animation from './Animation';
import './Shuffle.css';

export default class Shuffle extends Animation {

	constructor (master, model, discard) {

		super(master, 300);
		this.model = model;
		this.discard = discard;
		//this.loadAudio("draw");
	}

	run () {

		var el = document.querySelector((this.discard ? ".discard-info" : ".deck-info") + " .card-shuffle-deck:not(.card-shuffle-deck-anim)");
		//var text = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-afflict-status:not(.status-anim) .game-entity-afflict-status-text");
		var img = document.querySelector((this.discard ? ".discard-info" : ".deck-info") + " .card-shuffle-deck:not(.card-shuffle-deck-anim) img");
		if (el) {
			el.classList.add("card-shuffle-deck-anim");
			img.src = this.model.img;
			//text.textContent = this.status.name;
			setTimeout(() => el.classList.remove("card-shuffle-deck-anim"), 500);
		}
	}
}