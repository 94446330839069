import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Block extends Bloc {

	constructor (src, ctx) {

		super("block", src, ctx, true);
		this.f = (src, ins) => {
			ins[0].gainblock(ins[0].modifyBlock(ins[1]));
			return [];
		};
		this.types = [Types.entity, Types.int];
	}
}