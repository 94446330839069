import Bloc from './Bloc.js';
import Types from './Types.js';

export default class FilterStats extends Bloc {

	constructor (src, ctx) {

		super("filterstats", src, ctx);
		this.f = (src, ins) => {

			var parsing = value => isNaN(value) ? parseInt(value, 10) : value;
			var checkmana = target => (ins[0] === null || (!isNaN(target.mana) && parsing(target.mana) >= ins[0])) && (ins[1] === null || (!isNaN(target.mana) && parsing(target.mana) <= ins[1]));
			var checklevel = target => (ins[2] === null || (!isNaN(target.level) && parsing(target.level) >= ins[2])) && (ins[3] === null || (!isNaN(target.level) && parsing(target.level) <= ins[3]));
			var cardfilter = target => checkmana(target) && checklevel(target);
			var modelfilter = target => checkmana(target) && checklevel(target);
			
			return [cardfilter, modelfilter];
		};
		this.types = [Types.int, Types.int, Types.int, Types.int];
	}
}