import Bloc from './Bloc.js';
import Types from './Types.js';

export default class GainGold extends Bloc {

	constructor (src, ctx) {

		super("gaingold", src, ctx, true);
		this.f = (src, ins) => {
			src.master.run.champion.gainGold(ins[0]);
			return [];
		};
		this.types = [Types.int];
	}
}