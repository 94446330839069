import React, { Component } from 'react';
import './Scene.css';
import Hand from './view/Hand';
import Champion from './view/Champion';
import Enemies from './view/Enemies';
import Court from './view/Court';
import Field from './view/Field';
import Hero from './view/Hero';
import Reward from './view/Reward';
import LevelUp from './view/LevelUp';
import Campfire from './view/Campfire';
import CardSelector from './view/CardSelector';
import CardDropper from './view/CardDropper';
import DeckViewer from './view/DeckViewer';
import Abilities from './view/Abilities';
import Choosebox from './view/Choosebox';
import Cutscene from './view/Cutscene';

import { createStore } from 'redux';
import reducers from './reducers';
import { Tooltip } from 'reactstrap';

import Lightbox from '../components/utility/Lightbox';
import SceneButton from '../components/buttons/SceneButton';
import MainButton from '../components/buttons/MainButton';
import Card from '../components/cards/Card';
import Ability from '../components/cards/Ability';
import CardBox from '../components/cards/CardBox';
import SocketManager from '../SocketManager';
import Sequencer from './Sequencer';
import Logs from './Logs';
import Error from '../components/home/Error';
import Back from '../components/Back';

import PlayingState from './controller/PlayingState';
import WaitingState from './controller/WaitingState';
import HandChoiceState from './controller/HandChoiceState';
//import MovingState from './controller/state/MovingState';

import { read } from '../TextManager';
import { play, stopMusic, stopCategory } from '../SoundManager';
import CutsceneManager from './CutsceneManager';

import Library from './utility/Library';
import User from '../User';
import Img from '../StableImage';

import Run from './model/Run';
import Broadcaster from './Broadcaster';

const backgrounds = [
  "floor1",
  "floor2"
]

const audio = [
  "Tenno - The Guiding Wind",
  "Tenno - Sacred Tree"
]

export default class Scene extends Component {
	
  constructor (props) {

    super(props);

    this.store = createStore(reducers);
    this.store.subscribe(() => {
      this.setState({model: this.store.getState()}, () => { if (this.sequencer.resume) this.sequencer.resume(); });
    });

    this.checkAudio = () => {

      if (this.audioPlaying && !this.cutsceneManager.cutscene)
        play(this.run.audio || audio[this.run.depth-1], "music");
    }

    document.addEventListener('click', this.checkAudio, { once: true });
    document.addEventListener('keydown', this.checkAudio, { once: true });

    this.volume = 1;
    //this.noPlayer = 0;
    this.controller = new WaitingState(this);
    this.sequencer = new Sequencer(this, n => this.store.dispatch(n));

    this.cutsceneManager = new CutsceneManager(this, c => this.cutscene(c));

    //let decks = JSON.parse(localStorage.getItem('decks'))
    //let activedeck = decks.filter(deck => deck.key.toString() === localStorage.getItem('activedeck'))[0].body;
    //this.io.gamemode('room', activedeck);

    let broadcaster = new Broadcaster();

    let save = User.currentRun;
    this.run = null;
    if (save) {
      this.run = Run.build(save, broadcaster);
      setTimeout(() => {
        let savelog = User.getItem("run.log");
        if (savelog)
          broadcaster.log = JSON.parse(savelog);
        if (this.run?.room?.fight?.choosingHand)
          this.controller = new HandChoiceState(this, this.run.room.fight);
        else if (this.run?.room?.fight?.turn === "player")
          this.controller = new PlayingState(this, this.run.room.fight);
        else
          this.controller = new WaitingState(this);
        this.run.notify('gamestate', this.run.serialize(), broadcaster.log);
      }, 10);
    } else {
      this.run = new Run();
      setTimeout(() => {
        this.run.notify('newrun', this.run, this.run.champion.model.key)
        this.run.start();
      }, 10);
    }
    
    if (!save)
      this.run.init(broadcaster, User.championSelect, User.difficulty);
    broadcaster.start(this);
    

    this.grabbing = null;
    this.dragged = React.createRef();
    this.state = {
      model: this.store.getState(),
      focus:null,
      casting: null,
      tooltip: null, 
      deckviewer: false
    }
  }

  componentWillUnmount () {

    stopMusic();

    document.removeEventListener('click', this.checkAudio);
    document.removeEventListener('keydown', this.checkAudio);
  }

  /*componentDidMount() {

    this.unloadListener = () => this.save();
    window.addEventListener('beforeunload', this.unloadListener);
  }

  componentWillUnmount () {

    this.save();
    window.removeEventListener('beforeunload', this.unloadListener);
  }*/

  save () {

    if (this.run.over)
      return;
    User.setItem("run.save", JSON.stringify(this.run.serialize()));
    User.setItem("run.log", JSON.stringify(this.run.broadcaster.log));
  }

  get playing () { return this.controller.name === "playing" }
  get targeting () { return this.controller.name === "playing" && this.controller.selected }
  get waiting () { return this.controller.name === "waiting" }
  get choosing () { return this.controller.name === "choosing" }

  notify (type, data) {

    this.cutsceneManager.event({type, data}, () => {
      this.sequencer.add({n: {type, data}, callback: () => this.actUponUpdate(type, data)}); 
    })
  }

  cutscene (c) {

    this.setState({cutscene: c ? Object.assign({}, this.state.cutscene, c) : null});
  }

  actUponUpdate (type, data) {

    switch (type) {
    case "gamestate":
    case "newfloor":
      this.audioPlaying = true;
      play(this.run.audio || audio[this.run.depth-1], "music");
      break;
    case "victory":
      let progress = User.progress;
      let quest = User.getQuest(this.run.champion.model.key, this.run);
      if (this.run.storyEvent) {
        User.advance("story", this.run.champion.model.key);
        User.removeItem("run.save");
        User.removeItem("run.log");
      }
      else if (!(quest && quest.active) && this.run.depth === progress[this.run.champion.model.key].level && this.run.depth < 2) {
        User.advance("level", this.run.champion.model.key);
        User.removeItem("run.save");
        User.removeItem("run.log");
      } else if (!(quest && quest.active)) {
        User.removeItem("run.save");
        User.removeItem("run.log");
      }
      this.controller = new WaitingState(this);
      break;
    case "gameover":
      User.removeItem("run.save");
      User.removeItem("run.log");
      this.controller = new WaitingState(this);
      break;
    case "playerturn":
      this.save();
    case "chosehand":
      this.controller = new PlayingState(this, this.run.room.fight);
      break;
    case "newcardchoice":
    case "campfire":
    case "fightreward":
      this.save();
      break;
    case "endplayerturn":
      this.controller = new WaitingState(this);
      break;
    case "handchoice":
      this.controller = new HandChoiceState(this, this.run.room.fight);
      break;
    default: break;
    }
  }

  toggleTooltip (tooltip) {

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  onSelectCard (e, card) {

    if (this.controller.selectCard) {
      this.controller.selectCard(card);
      this.forceUpdate()
    }
  }

  onSelectEntity (e, entity) {

    if (this.controller.selectEntity)
      this.controller.selectEntity(entity);
  }

  endTurn () {

    if (this.controller.endTurn)
      this.controller.endTurn();
  }

  unselect () {

    if (this.controller && this.controller.unselect) {
      this.controller.unselect();
      this.forceUpdate()
    }
  }

  toggleTooltip (name) {

    this.setState({tooltip: this.state.tooltip === name ? undefined : name});
  }

	render () {

    if (this.state.model === 0)
      return (
      <div className="scene">
        {/*<div className="scene-background">
          <img src={"/images/backgrounds/" + backgrounds[0] + ".png"}/>
        </div>*/}
        {
          this.state.cutscene ?
          <Cutscene back={() => this.cutsceneManager.back()} skip={() => this.cutsceneManager.skip()} continue={() => this.cutsceneManager.continue()} src={this.state.cutscene}/> : ""
        }
      </div>);

    let quest = User.getQuest(this.run.champion.model.key, this.run);

		return (
      <div className={"scene playing"}>
        <div className={"scene-background background-" + (this.state.model.background ?? backgrounds[this.state.model.depth-1])} onClick={() => this.unselect()}>
          <Img key={"background-" + (this.state.model.background ?? backgrounds[this.state.model.depth-1])} src={"/images/backgrounds/" + (this.state.model.background ?? backgrounds[this.state.model.depth-1]) + ".png"}/>
          <div className="background-particle"/><div className="background-particle"/><div className="background-particle"/><div className="background-particle"/>
        </div>
        <div className="champion-info">
          <div id="champion-level" className="champion-stat level">
            <Img src={"/images/level/" + this.state.model.champion.model.name + ".png"}/>
            <div className="stat-count">{this.state.model.champion.level}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "champion-level"} target="champion-level" toggle={() => this.toggleTooltip("champion-level")}><b>Level</b><br/>{this.run.champion.model.passive.effect.replace(/\[|\]/g, '')}</Tooltip>
          <div id="champion-health" className="champion-stat health">
            <Img src="/images/health.png"/>
            <div className="stat-count">{this.state.model.champion.hp}/{this.state.model.champion.maxhp}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "champion-health"} target="champion-health" toggle={() => this.toggleTooltip("champion-health")}><b>Health</b><br/>Don't let it drop to 0!</Tooltip>
        </div>
        <div className="champion-info">
          <div id="gold" className="champion-stat gold">
            <Img src="/images/gold.png"/>
            <div className="stat-count">{this.state.model.champion.gold}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "gold"} target="gold" toggle={() => this.toggleTooltip("gold")}><b>Gold</b><br/>No use in this update.</Tooltip>
          <div id="corruption" className="champion-stat corruption">
            <Img src="/images/corruption.png"/>
            <div className="stat-count">{this.state.model.champion.corruption}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "corruption"} target="corruption" toggle={() => this.toggleTooltip("corruption")}><b>Corruption</b><br/>No use in this update.</Tooltip>
          <div id="floor" className="champion-stat floor">
            <Img src="/images/stairs.png"/>
            <div className="stat-count">{this.state.model.storyEvent ? "SP" : this.state.model.depth + "-" + (this.state.model.floor ? this.state.model.floor.length : 0)}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "floor"} target="floor" toggle={() => this.toggleTooltip("floor")}><b>Current floor</b><br/>{"A boss waits for you in the " + (this.run.casual ? "12" : "16") + "th room."}</Tooltip>
        </div>
        <div>
          <div id="champion-difficulty" className="champion-difficulty">
            <Img src={"/images/" + this.run.difficultySetting + ".png"}/>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "difficulty"} target="champion-difficulty" toggle={() => this.toggleTooltip("difficulty")}>{ this.run.challenge ? <span><b>Challenge mode</b><br/>The maze is unforgiving.</span> : <span><b>Casual mode</b><br/>The maze welcomes all.</span>}</Tooltip>
        </div>
        <div id="deck-viewer-open" className={"deck-viewer-open-wrapper" + (this.state.deckviewer ? " open" : "")}>
          <div className="deck-viewer-open" onClick={() => this.setState({deckviewer: !this.state.deckviewer})}>
            <Img src="/images/card.png"/>
          </div>
        </div>
        {/*<Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "deck-viewer-open" && !this.state.deckviewer} target="deck-viewer-open" toggle={() => this.toggleTooltip("deck-viewer-open")}><b>Deck viewer</b><br/>Cards your deck starts with every fight.</Tooltip>*/}
        <div id="exp-bar" className={"exp-bar-wrapper" + (this.state.model.champion.exp >= this.state.model.champion.threshold || this.state.model.levelingUp ? " levelup" : "")}>
          <div className="exp-bar-text">
            {
              this.state.model.champion.level >= this.state.model.champion.maxLevel
              ? "Max level" : (
                this.state.model.champion.exp >= this.state.model.champion.threshold || this.state.model.levelingUp
                ? "Level Up!"
                : this.state.model.champion.exp + "/" + this.state.model.champion.threshold
              )
            }
          </div>
          <div className="exp-bar">
            <div className="exp-bar-fill" style={{width: (this.state.model.levelingUp ? 100 : (100 * this.state.model.champion.exp / this.state.model.champion.threshold)) + "%"}}/>
          </div>
        </div>
        <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "expbar"} target="exp-bar" toggle={() => this.toggleTooltip("expbar")}><b>Experience gauge</b><br/>Fill it up to level up.</Tooltip>
        <div className="scene-relic-list">
        {
          this.state.model.champion.relics.map(relic =>
          <div key={relic.key} id={"relic-" + relic.key} className="scene-relic">
            <Img src={relic.img}/>
            <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "relic-" + relic.key} target={"relic-" + relic.key} toggle={() => this.toggleTooltip("relic-" + relic.key)}><b>{ relic.name }</b><br/>{ relic.effect }</Tooltip>
          </div>)
        }
        </div>
        {
          quest ?
          <div className={"scene-quest" + (quest.active ? " active" : "")} onClick={quest.callback || (() => {})}>
            <h2>Quest</h2>
            <div className="quest-text">{quest.text}</div>
          </div>
          : ""
        }
      {
        this.state.model.room?.fight ?
        <div className={"fight-scene " + (this.controller.name)}>
          { this.state.model.room.fight.rewarding ? <Reward src={this.state.model.room.fight} collect={i => this.run.room.fight.collect(i)} collectCard={i => this.run.room.fight.collectCard(i)} cleanup={() => this.run.room.cleanup()}/> : "" }
          <Champion src={this.state.model.room.fight.player} onSelect={() => {}}/>
          <Enemies
            hoverTarget={enemy => {
              if (this.state.enemy !== enemy)
                this.setState({hoverTarget: enemy});
            }}
            targeting={this.targeting ? this.controller.selected : undefined}
            src={this.state.model.room.fight.enemies}
            onSelect={this.onSelectEntity.bind(this)}/>
          <div id="mana" className="mana-count-wrapper">
            <Img src="/images/mana.png"/>
            <div className="mana-count">{this.state.model.room.fight.mana}</div>
          </div>
          <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "mana"} target="mana" toggle={() => this.toggleTooltip("mana")}><b>Mana</b><br/>Helps you pay for your cards.</Tooltip>
          <Hand targeting={this.targeting} target={this.state.hoverTarget} src={this.state.model.room.fight.hand} onFocus={model => this.setState({tooltip: null, focus: model})} onSelect={this.onSelectCard.bind(this)} getHalo={card => this.controller?.cardHalo?.(card)}/>
          <div className="locations-info">
            <div id="deck-info" className="location-info deck-info">
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <Img className="location-icon" src="/images/deck.png"/><div className="location-count deck-count">{this.state.model.room.fight.deck.count}</div>
            </div>
            <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "deck-info"} target="deck-info" toggle={() => this.toggleTooltip("deck-info")}><b>Cards in deck</b><br/>Draw your cards from this pile.</Tooltip>
            <div id="discard-info" className="location-info discard-info">
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <Img className="location-icon" src="/images/hand.png"/>
            <div className="location-count discard-count">{this.state.model.room.fight.discard.count}</div></div>
            <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "discard-info"} target="discard-info" toggle={() => this.toggleTooltip("discard-info")}><b>Cards in discard</b><br/>Shuffled back into your deck at end of turn.</Tooltip>
          </div>
          <div className="fight-end-turn"><SceneButton onClick={() => this.endTurn()}>End turn</SceneButton></div>
        </div> : ""
      }
      {
        this.state.model.room?.choices ?
        <Campfire src={this.state.model.room} onSelect={choice => this.run.room.chooseOption(choice)}/> : ""
      }
      { this.state.model.choosingCard ?
        <div className="fight-loot-card lightbox-container">
          <div className="lightbox-inner">
            <CardSelector options={this.state.model.options} onFocus={model => this.setState({tooltip: null, focus: model})} onSelect={i => this.run.collectCard(i)}/>
          </div>
        </div> : ""
      }
      {
        this.state.model.levelingUp && !this.state.model.droppingCard ?
        <LevelUp champion={this.state.model.champion} options={this.state.model.options} collectCard={i => this.run.collectCard(i)}/> : ""
      }
      {
        this.state.model.droppingCard ?
        <CardDropper deck={this.state.model.deck} onFocus={model => this.setState({tooltip: null, focus: model})} dropCard={i => this.run.dropCard(i)}/> : ""
      }
      {
        this.state.deckviewer ?
        <DeckViewer deck={this.state.model.deck} onClose={() => this.setState({deckviewer: false})}/> : ""
      }
      {
        this.state.focus ? <CardBox src={this.state.focus} open={true} onClose={() => this.setState({focus:null})}/> : ""
      }
      {
        this.state.model.over || this.state.model.win ?
        <div className={"game-over" + (this.state.model.win ? " game-over-win" : "")}>
          <h2>{ this.state.model.win ? "Victory" : "Game Over"}</h2>
          <div className="game-over-quote">
          {
            this.state.model.win ?
            (
              this.run.depth < 2 && !this.run.storyEvent ?
              <div>
                <span>The maze has grown... and so can you.</span>
                <span>Growth level {this.run.depth+1} unlocked on future runs.</span>
                <span>Floor {this.run.depth+1} unlocked on future runs.</span>
              </div> :
              <div>
                <span>The maze values your ambition.</span>
                <span>{ quest ? "Accomplish your destiny." : "More content in a future update."}</span>
              </div>

            ) :
            <div>
              <span>Maybe next time...</span>
            </div>
          }
          </div>
          <div className="game-over-recap">
            <div className="game-over-champion">{ this.state.model.champion.model.name }</div>
            <div className="game-over-stats">
              <div className="game-over-stat">
                <div className="game-over-stat-name">Level</div><div className="game-over-stat-value">{ this.run.stats.level }</div>
              </div>
              <div className="game-over-stat">
                <div className="game-over-stat-name">Rooms cleared</div><div className="game-over-stat-value">{ this.run.stats.roomsCleared }</div>
              </div>
              <div className="game-over-stat">
                <div className="game-over-stat-name">Bosses slain</div><div className="game-over-stat-value">{ this.run.stats.bossesSlain }</div>
              </div>
            </div>
          </div>
          {
            quest && quest.active && this.state.model.win ?
            <MainButton onClick={quest.callback ?? (() => {})}>{quest.text}</MainButton> :
            <MainButton to="/play">Main Menu</MainButton>
          }
        </div> : ""
      }
      {
        this.state.cutscene ?
        <Cutscene back={() => this.cutsceneManager.back()} skip={() => this.cutsceneManager.skip()} continue={() => this.cutsceneManager.continue()} src={this.state.cutscene}/> : ""
      }
      </div>
		);
	}
}