import React, { Component } from 'react';
import './Enemies.css';
import Entity from './Entity';

export default class Enemies extends Component {

  render () {

    return (
      <div className="game-enemies">
      {
        this.props.src.map(enemy => 
          <div key={enemy.key}
            className={"enemy-wrapper" + (enemy.dead ? " dead" : "") + (this.props.targeting && this.props.targeting.canTarget(enemy) ? " target-enemy" : "")}
            onMouseMove={() => { if (this.props.targeting) this.props.hoverTarget(enemy); }}
            onMouseOut={() => this.props.hoverTarget(null) }
            >
            <Entity onSelect={this.props.onSelect} src={enemy}/>
          </div>)
      }
      </div>
    );
  }
}

