import React, { Component } from 'react';
import Lightbox from '../../components/utility/Lightbox';
import './LevelUp.css';
import CardSelector from './CardSelector';
import Img from '../../StableImage';

export default class LevelUp extends Component {

  render () {

    return (
      <div className="level-up-screen">
        <div className="fight-loot-card lightbox-container">
          <div className="lightbox-inner">
            <div className="level-up-comparison">
              <div className="level">
                <Img className="level" src={this.props.champion.model.img[this.props.champion.level-2]}/>
                <div className="level-indicator">{"Lv." + (this.props.champion.level-1)}</div>
                <div className="level-health">
                  <Img className="level-health-img" src="/images/health.png"/>
                  <div className="level-health-value">{this.props.champion.maxhp - this.props.champion.level*20 * (this.props.champion.run.casual ? (5/4) : 1)}</div>
                </div>
              </div>
              <Img className="arrow" src="/images/arrow.png"/>
              <div className="level">
                <Img className="level" src={this.props.champion.model.img[this.props.champion.level-1]}/>
                <div className="level-indicator">{"Lv." + (this.props.champion.level)}</div>
                <div className="level-health">
                  <Img className="level-health-img" src="/images/health.png"/>
                  <div className="level-health-value">{this.props.champion.maxhp}</div>
                </div>
              </div>
            </div>
            <CardSelector options={this.props.options} onSelect={i => this.props.collectCard(i)}/>
          </div>
        </div>
      </div>
    );
  }
}

