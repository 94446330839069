import Bloc from './Bloc.js';
import Types from './Types.js';

export default class CountCards extends Bloc {

	constructor (src, ctx) {

		super("countcards", src, ctx);
		this.f = (src, ins) => [ins[0].cards.filter(card => ins[1](card)).length];
		this.types = [Types.location, Types.cardfilter];
	}
}