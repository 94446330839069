import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Listener extends Bloc {

	constructor (src, ctx, target) {

		super("listener", src, ctx, true);
		this.types = [Types.event];
		this.out = [this, null];
	}

	execute (props) {
		
		props = props || {};
		props.trace = props.trace || [];
		props.trace.push(this);
		this.out = [this, this.data];
		if (this.to)
			this.to.execute(props);
	}

	setup (owner, image) {

		let run = owner.master.run ? owner.master.run : owner.master;
		if (!run.broadcaster)
			return;

		var that = this;
		owner.passives = owner.passives || [];
		let event = this.in[0]({ src: owner, image: image });
		if (typeof event === 'string')
			event = [event];
		event.forEach(e => owner.passives.push(run.subscribe(e, (t,d) => {
			that.data = d;
			that.execute({ src: owner, image: image });
		})));
	}
}