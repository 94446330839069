import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Mana extends Bloc {

	constructor (src, ctx) {

		super("mana", src, ctx);
		this.f = (src, ins) => [src.master.mana];
		this.types = [];
	}
}