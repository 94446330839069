import User from '../User';
import allcutscenes from '../library/cutscenes.json';
import images from '../library/images.json';
import { play, getMusic, stopMusic, stopCategory } from '../SoundManager';

const cutscenes = {};

Object.values(allcutscenes).forEach(cutscene => {
    const { champion, type, value } = cutscene;

    if (!cutscenes[champion]) {
        cutscenes[champion] = {};
    }

    if (!cutscenes[champion][type]) {
        cutscenes[champion][type] = value ? {} : cutscene;
    }

    if (value) {
        cutscenes[champion][type][value] = cutscene;
    }
});

export default class CutsceneManager {

	queue = [];

	static get cutscenes () {

		return cutscenes;
	}

	constructor (master, dispatch) {

		this.master = master;
		this.dispatch = dispatch;
	}

	start (cutscene) {

		if (!cutscene.fast)
			User.watch(cutscene.key);
		this.music = getMusic();
		stopMusic();
		this.cutscene = Object.assign({}, cutscene);
		this.cutscene.sequence = this.cutscene.sequence.map(e => Object.assign({}, e));
		this.cutscene.sequence.forEach((e, i) => {
			e.first = i === 0;
			e.hideLeft = e.left === "";
			e.hideRight = e.right === "";
			e.hideLeft2 = e.left2 === "";
			e.hideRight2 = e.right2 === "";
			["background", "img", "speaker", "text", "left", "right", "left2", "right2", "focus"].forEach(prop => {
				if (prop === "background" || prop === "left" || prop === "right" || prop === "left2" || prop === "right2")
					e[prop] = images[e[prop]];
				if (e[prop] === undefined || (e[prop] === "" && (prop === "left" || prop === "right" || prop === "left2" || prop === "right2"))) {
					if (e.first)
						e[prop] = undefined;
					else if (prop === "left" && this.cutscene.sequence[i-1].hideLeft || prop === "right" && this.cutscene.sequence[i-1].hideRight  || prop === "left2" && this.cutscene.sequence[i-1].hideLeft2  || prop === "right2" && this.cutscene.sequence[i-1].hideRight2)
						e[prop] = "";
					else {
						e[prop] = this.cutscene.sequence[i-1][prop];
					}
				}
			});
		})
		this.sequence = 0;
		this.dispatch(this.cutscene.sequence[this.sequence]);
	}

	get run () {

		return this.master.state.model;
	}

	get championKey () {

		return this.run.champion.model.key;
	}

	next () {

		if (this.queue.length > 0) {
			let next = this.queue.shift();
			this.event(next.n, next.callback);
		}
	}

	back () {

		if (!this.cutscene || this.sequence === 0)
			return;
		this.sequence--;
		this.dispatch(this.cutscene.sequence[this.sequence]);
	}

	skip () {

		if (!this.cutscene)
			return;
		delete this.sequence;
		delete this.cutscene;
		if (this.music) {
			play(this.music, "music");
			delete this.music;
		}
		if (this.callback) {
			this.dispatch();
			this.callback();
			delete this.callback;
		}
		this.next();
	}

	continue () {

		if (!this.cutscene)
			return;
		this.sequence++;
		if (this.sequence > this.cutscene.sequence.length - 1)
			this.skip();
		else
			this.dispatch(this.cutscene.sequence[this.sequence]);
	}

	call () {

		this.callback();
		delete this.callback;
		this.next();
	}

	event(n, callback) {

		if (this.callback) {
			this.queue.push({n, callback});
			return;
		}

		this.callback = callback;
		let championKey = this.run?.champion?.model.key;

		switch (n.type) {
		case "storyevent": {
			this.start(cutscenes[championKey]["story"]["event1-intro"])
			break;
		}
		case "victory": {
			if (this.run.storyEvent) {
				setTimeout(() => this.start(cutscenes[championKey]["story"]["event1-victory"]), 2000);
				break;
			}
			if (this.run.depth < 2) {
				let cutscene = cutscenes[championKey]["story"]["boss" + this.run.depth];
				if (!User.hasSeen(cutscene.key))
					setTimeout(() => this.start(cutscene), 2000);
				else
					this.call()
			}
			else
				this.call()
			break;
		}
		case "newrun": {
			let cutscene = cutscenes[n.data[1]]["story"]["intro"];
			if (!User.hasSeen(cutscene.key))
				this.start(cutscene)
			else
				this.call()
			break;
		}
		case "playerturn": {
			let tutorial = cutscenes["-1"]["tutorial"]["intro"];
			if (!User.hasSeen(tutorial.key))
				this.start(tutorial)
			else
				this.call()
			break;
		}
		case "levelup": {
			let cutscene = cutscenes[this.championKey]["level up"][this.run.champion.level+1];
			this.start(User.hasSeen(cutscene.key) ? cutscenes[this.championKey]["level up"][this.run.champion.level+1+"-fast"] : cutscene)
			break;
		}
		default:
			this.call();
			break;
		}
	}
}