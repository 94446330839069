import Bloc from './Bloc.js';
import Types from './Types.js';

export default class HandChoice extends Bloc {

	constructor (src, ctx) {

		super("handchoice", src, ctx, true);
		this.f = (src, ins, props) => {
			this.resolve = {src, n:ins[0]};
			this.next(props);
			return [];
		};
		this.types = [Types.int];
		this.toPrepare.push("for each");
		this.toPrepare.push("completed");
	}

	execute (props) {
		
		props = props || {};
		let src = props.src || this.src;
		props.trace = props.trace || [];
		props.trace.push(this);
		var f = this.f || (() => []);
		this.out = f(src, this.computeIn(props), props);
	}

	next (props) {

		if (!this.resolve.n || this.resolve.src.master.hand.isEmpty) {
			if (this.completed)
				this.completed.execute();
			return;
		}
		this.resolve.n--;
		if (this.resolve.src.master.hand.count === 1) {
			this.out = [this.resolve.src.master.hand.cards[0]];
			this["for each"].execute(props);
			this.next(props);
			return;
		}
		if (this.src.autocast) {
			let card = this.resolve.src.master.hand.cards[Math.floor(Math.random()*this.resolve.src.master.hand.cards.length)];
			this.out = [card];
			this["for each"].execute(props);
			this.next(props);
		}
		this.src.master.handChoice(card => {
			this.out = [card];
			this["for each"].execute(props);
			this.next(props);
		})
	}
}