import React, { Component } from 'react';
import Lightbox from '../../components/utility/Lightbox';
import { Tooltip } from 'reactstrap';
import './Reward.css';
import Img from '../../StableImage';

export default class Reward extends Component {

  state = {

    tooltip: null
  }

  toggleTooltip (tooltip) {

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  render () {

    let tooltips = []

    return (
      <div className="fight-reward-wrapper">
        <Lightbox className="fight-reward" open={true}>
          <h1>Reward</h1>
          <div className="fight-loot">
          {
            this.props.src.loot.map((loot, i) =>
              <div key={i} className="fight-loot-piece" onClick={()=>{}/*() => this.props.collect(i)*/}>
                {(() => {
                  switch (loot.type) {
                  case "card": return <div className="loot-icon"><Img src="/images/card.png"/><span>New card</span></div>
                  case "gold": return <div className="loot-icon"><Img src="/images/gold.png"/><span>{loot.value}</span></div>
                  case "exp": return <div className="loot-icon"><Img src="/images/exp.png"/><span>{loot.value}</span></div>
                  case "relic":
                    tooltips.push(<Tooltip key={"tooltip-relic-" + i} className="tooltip" placement="top" isOpen={this.state.tooltip === "relic-" + loot.relic.key} target={"loot-relic-" + loot.relic.key} toggle={() => this.toggleTooltip("relic-" + loot.relic.key)}>{ loot.relic.effect }</Tooltip>)
                    return <div id={"loot-relic-" + loot.relic.key} className="loot-icon"><Img src={ loot.relic.img }/><span>{loot.relic.name}</span></div>
                  }
                })()}
              </div>
            )
          }
          </div>
          <div className="reward-tooltips">{ tooltips }</div>
          <div className="fight-reward-end" onClick={() => this.props.cleanup()}>Continue</div>
        </Lightbox>
      </div>
    );
  }
}

