import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Trigger extends Bloc {

	constructor (name, src, ctx, event) {

		super(name, src, ctx);
		this.f = (src, ins) => [ event + (ins[0] ? "" : ".before") ];
		this.types = [Types.bool];
	}
}