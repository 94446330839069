import Animation from './Animation';
import './Intent.css';

export default class Intent extends Animation {

	constructor (master, entity) {

		super(master, 600);
		this.entity = entity;
		//this.loadAudio("draw");
	}

	run () {

		var el = document.querySelector("#game-entity-" + this.entity + " .game-entity-main");
		if (el) {
			el.classList.add("intent-anim");
			//setTimeout(() => el.classList.remove("intent-anim"), 800);
		}
	}
}