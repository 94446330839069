import Bloc from './Bloc.js';
import Types from './Types.js';

export default class BreakCard extends Bloc {

	constructor (src, ctx) {

		super("breakcard", src, ctx);
		this.f = (src, ins) => [ins[0].model];
		this.types = [Types.card];
	}
}