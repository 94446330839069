import Fight from './Fight';

export default class Room {

	constructor (run, model) {

		this.run = run;
		this.model = model;
		this.run.notify('newroom', this);
	}

	get type () { return this.model.type; }

	enter () {

		switch (this.type) {
		case "story":
			this.startFight();
			break;
		case "fight":
			if (this.run.depth === 1 && this.run.floor.length === 1)
				this.startFight();
			else {
				this.choices = ["rest", "explore"];
				if (this.run.champion.level < this.run.champion.maxLevel)
					this.choices.push("worship");
				this.run.notify('campfire', this, this.choices);
			}
			break;
		default: break;
		}
	}

	chooseOption (choice) {

		switch (choice) {
		case "rest":
			this.run.champion.heal(this.restHealing);
			break;
		case "explore":
			this.run.cardChoice();
			return;
		case "worship":
			this.run.champion.loseCorruption(this.worshipCorruption);
			this.run.champion.gainExp(this.worshipExp);
			let lvup = this.run.champion.checkLevelUp();
			if (lvup)
				return;
			break;
		default: break;
		}

		delete this.choices;
		this.startFight();
	}

	startFight () {

		this.fight = new Fight(this.run);
		this.fight.start(this.model.enemies);
	}

	get restHealing () { return this.run.depth * (this.run.casual ? 15 : 10)/*Math.floor(this.run.champion.maxhp/5)*/; }

	get worshipExp () { return this.run.depth * 1 - Math.min(this.run.depth * 1, this.run.champion.corruption); }
	get worshipCorruption () { return Math.min(this.run.depth * 1, this.run.champion.corruption); }

	cleanup () {

		let lvup = this.run.champion.checkLevelUp();
		if (!lvup) {
			if (this.type === "fight" && !this.fight)
				this.startFight();
			else
				this.run.next();
		}
	}

	serialize () {

		return {
			model: this.model,
			fight: this.fight?.serialize(),
			choices: this.choices
		}
	}

	static build (run, src) {

		let room = new Room(run, src.model);
		if (src.fight)
			room.fight = Fight.build(run, src.fight);
		room.choices = src.choices;
		return room;
	}
}