import React, { Component } from 'react';
import './Cutscene.css';

export default class Cutscene extends Component {

  render () {

    return (
      <div className="cutscene" onClick={() => this.props.continue()}>
        <div className="lightbox-container">
          <div className="lightbox-inner">
            <div className="cutscene-inner">
              {
                this.props.src.background ?
                <div className={"cutscene-background" + (this.props.src.background ? "" : " none")}>
                  <img key={"bg-" + this.props.src.left} className="no-select" src={this.props.src.background}/>
                </div> : ""
              }
              <div className={"cutscene-img" + (this.props.src.img ? "" : " none")}>
                <img key={"img-" + this.props.src.left} className="no-select" src={this.props.src.img}/>
              </div>
              {
                this.props.src.left ?
                <div className={"cutscene-left cutscene-character" + (this.props.src.focus && this.props.src.focus !== "left" ? " unfocus" : "") + (this.props.src.hideLeft ? " hide" : "")}>
                  <img key={"left-" + this.props.src.left} className="no-select" src={this.props.src.left}/>
                </div> : ""
              }
              {
                this.props.src.right ?
                <div className={"cutscene-right cutscene-character" + (this.props.src.focus && this.props.src.focus !== "right" ? " unfocus" : "") + (this.props.src.hideRight ? " hide" : "")}>
                  <img key={"right-" + this.props.src.left} className="no-select" src={this.props.src.right}/>
                </div> : ""
              }
              {
                this.props.src.left2 ?
                <div className={"cutscene-left2 cutscene-character" + (this.props.src.focus && this.props.src.focus !== "left2" ? " unfocus" : "") + (this.props.src.hideLeft2 ? " hide" : "")}>
                  <img key={"left2-" + this.props.src.left} className="no-select" src={this.props.src.left2}/>
                </div> : ""
              }
              {
                this.props.src.right2 ?
                <div className={"cutscene-right2 cutscene-character" + (this.props.src.focus && this.props.src.focus !== "right2" ? " unfocus" : "") + (this.props.src.hideRight2 ? " hide" : "")}>
                  <img key={"right2-" + this.props.src.left} className="no-select" src={this.props.src.right2}/>
                </div> : ""
              }
              <div className="cutscene-textbox">
                <div className="cutscene-speaker">{this.props.src.speaker}</div>
                <div className="cutscene-text">{this.props.src.text}</div>
                <div onClick={e => {this.props.back(); e.stopPropagation();}} className={"cutscene-back" + (this.props.src.first ? " locked" : "")}>Back</div>
                <div onClick={e => {this.props.skip(); e.stopPropagation();}} className={"cutscene-skip"}>Skip</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

