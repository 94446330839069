import React, { Component } from 'react';
import './DeckViewer.css';
import Card from '../../components/cards/Card';
import CardBox from '../../components/cards/CardBox';
import { Tooltip } from 'reactstrap';

export default class DeckViewer extends Component {

  state = {
    focus: null
  }

  render () {

    let left = null, right = null;

    return (
      <div className="card-dropper deck-viewer">
        <div className="lightbox-container" onClick={() => this.props.onClose()}>
          <div className="lightbox-inner">
            <div className="card-dropper-inner">
              <div className="card-dropper-options">
              {
                this.props.deck.map((card, i) => { return {card, index:i}}).sort((a, b) => a.card.key - b.card.key).map(sorted =>
                  <div key={sorted.index} className="card-dropper-option" onClick={e => {this.setState({focus: sorted.card}); e.stopPropagation();}}>
                    <div className="card-dropper-option-inner">
                      <Card src={sorted.card}/>
                    </div>
                  </div>
                )
              }
              </div>
            </div>
          </div>
        </div>
        {
          this.state.focus ?
          <div>
            <CardBox left={left ? () => this.setState({focus:left}) : undefined} right={right ? () => this.setState({focus:right}) : undefined} src={this.state.focus} open={true} onClose={() => this.setState({focus:null})}/>
          </div> : ""
        }
      </div>
    );
  }
}

