import Animation from './Animation';
import './Damage.css';

export default class Damage extends Animation {

	constructor (master, entity, dmg) {

		super(master, 0);
		this.entity = entity;
		this.dmg = dmg;
		this.loadAudio("damage");
	}

	run () {

		var el = document.querySelector("#game-entity-" + this.entity + " .game-entity-main");
		var dmg = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-damage:not(.game-entity-damage-anim)");
		if (el) {
			el.classList.add("game-entity-damage-anim");
			setTimeout(() => el.classList.remove("game-entity-damage-anim"), 600);
		}
		if (dmg) {
			dmg.classList.add("game-entity-damage-anim");
			dmg.textContent = this.dmg;
			setTimeout(() => dmg.classList.remove("game-entity-damage-anim"), 600);
		}

		if (this.entity === "player") {
			var bg = document.querySelector(".scene .scene-background");
			if (bg) {
				bg.classList.add("scene-background-damage");
				setTimeout(() => bg.classList.remove("scene-background-damage"), 600);
			}
		}
	}
}