import Animation from './Animation';

export default class BlockDamage extends Animation {

	constructor (master, entity) {

		super(master, 0);
		this.entity = entity;
		this.loadAudio("block");
	}

	run () {

	}
}