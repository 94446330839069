import Bloc from './Bloc.js';
import Types from './Types.js';

export default class GainCorruption extends Bloc {

	constructor (src, ctx) {

		super("gaincorruption", src, ctx, true);
		this.f = (src, ins) => {
			src.master.run.champion.gainCorruption(ins[0]);
			return [];
		};
		this.types = [Types.int];
	}
}