import Card from './Card';
import Champion from './Champion';
import Room from './Room';
import Library from '../utility/Library';
import User from '../../User';

export default class Run {

	init (broadcaster, champion, difficulty) {

		this.difficultySetting = difficulty;
		this.champion = new Champion(this, champion);
		this.deck = this.buildStartingDeck(this.champion);
		this.floors = [];
		this.broadcaster = broadcaster;
		this.cardChance = 0.5;
		this.eventChance = 0;
		this.maxFloor = Math.min(2, User.progress[this.champion.model.key].level);
		this.roomsCleared = 0;
		this.bossesSlain = 0;
	}

	start () {

		this.notify('gamestate', this.serialize());
		this.newFloor();
		this.newRoom();
		this.room.enter();
	}

	next () {

		if (this.storyEvent) {
			this.victory();
			return;
		}
		if (this.floor.length >= (this.casual ? 12 : 16)) {
			if (this.depth >= this.maxFloor) {
				this.victory();
				return;
			}
			this.newFloor();
		}
		//if (this.floor.length >= 4)
		//	this.floor.push(new Room(this, {key: 0, type: "rest"}));
		//else
			this.newRoom();
		this.room.enter();
	}

	gameOver () {

		this.over = true;
		this.notify('gameover', this);
	}

	victory () {

		this.win = true;
		this.notify('victory', this);
	}

	get rooms () { return this.floors.flat(); }

	get room () { return this.floor ? this.floor[this.floor.length-1] : null; }

	get floor () { return this.floors ? this.floors[this.floors.length-1] : null; }

	get depth () { return this.floors.length; }

	get stats () {

		return {
			champion: this.champion.model.key,
			roomsCleared: this.roomsCleared,
			bossesSlain: this.bossesSlain,
			level: this.champion.level
		}
	}

	buildStartingDeck (champion) {

		return champion.model.startingDeck.map(key => Library.getCard(key));
	}

	addCard (model) {

		this.deck.push(model);
		this.notify('addcard', this, model);
		this.dropChoice();
	}

	removeCard (n) {

		this.deck.splice(n, 1);
		this.notify('removecard', this, n);
	}

	newFloor () {

		this.champion.heal(this.champion.maxhp/2);
		this.floors.push([]);
		this.notify('newfloor', this);
	}

	get difficulty () {

		return this.getDifficulty(this.floor.length);
	}

	get casual () {

		return this.difficultySetting === "casual";
	}

	get challenge () {

		return this.difficultySetting === "challenge";
	}

	getDifficulty (room) {

		if (this.difficultySetting === "casual") {

			if (room === 12)
				return "boss";
			else if (room === 8)
				return "elite";
			else if (room < 4)
				return "easy";
			return "medium";
		}

		if (room === 16)
			return "boss";
		else if (room % 4 === 0 && room !== 4)
			return "elite";
		else if (room < 4)
			return "easy";
		/*else if (room > 12)
			return "hard";*/
		return "medium";
	}

	newRoom () {

		let rooms = Object.values(Library.rooms);
		rooms = rooms.filter(r => r.floor === this.depth && this.rooms.filter(a => a.model.key === r.key).length === 0);
		rooms = rooms.filter(r => r.difficulty === this.getDifficulty(this.floor.length + 1));
		if (this.casual)
			rooms = rooms.filter(r => !r.challenge);
		let room = rooms[Math.floor(Math.random() * rooms.length)];

		this.floor.push(new Room(this, room));
	}

	cardChoice () {

		this.choosingCard = true;

		let cards = Object.values(Library.cards);
		cards = cards.filter(c => c.champion === this.champion.model.key && c.level > 0 && c.level > this.champion.level - 3 && c.level <= this.champion.level);
		let cardlevels = Array.from({ length: this.champion.level }, (_, i) => cards.filter(c => c.level === i + 1));
		let lv1 = cardlevels.length > 3 ? cardlevels[cardlevels.length-3] : cardlevels[0];
		let lv2 = cardlevels.length > 2 ? cardlevels[cardlevels.length-2] : cardlevels[0];
		let lv3 = cardlevels[cardlevels.length-1];
		let options = [];
		for (let i = 0; i < 3; i++) {
			let option = null;
			let r = Math.random();
			let lv = r < 0.4 ? lv3 : (r < 0.7333 ? lv2 : lv1);
			while (option === null || options.includes(option))
				option = lv[Math.floor(Math.random() * lv.length)];
			options.push(option);
		}

		this.options = options;
		this.notify('newcardchoice', this, options);
	}

	levelUp () {

		this.levelingUp = true;
		this.choosingCard = true;

		let cards = Object.values(Library.cards);
		cards = cards.filter(c => c.champion === this.champion.model.key && c.level > 0 && c.level == this.champion.level);
		let options = [];
		for (let i = 0; i < 3; i++) {
			let option = null;
			while (option === null || options.includes(option))
				option = cards[Math.floor(Math.random() * cards.length)]
			options.push(option)
		}

		this.options = options;
		return options;
	}

	collectCard (n) {

		if (!this.options || !this.choosingCard)
			return;

		this.addCard(this.options[n]);

		delete this.options;
		delete this.choosingCard;
		this.notify('collectcard', this, n);
	}

	dropChoice () {

		this.droppingCard = true;

		this.notify('dropcardchoice', this);
	}

	dropCard (n) {

		if (!this.droppingCard)
			return;

		this.removeCard(n);

		delete this.droppingCard;
		this.notify('dropcard', this, n);

		if (this.levelingUp) {

			delete this.room.choices;
			delete this.levelingUp;
			this.room.cleanup();
		} else if (this.room?.choices && this.room?.type === "fight") {

			delete this.room.choices;
			this.room.startFight();
		}
	}

	startStoryEvent (enemies, background, audio) {

		delete this.win;
		if (this.room && this.room.fight)
			this.room.fight.cleanup();
		this.storyEvent = true;
		this.background = background;
		this.audio = audio;
		this.notify("storyevent", this, background, audio);
		//this.champion.heal(this.champion.maxhp);
		this.floors.push([]);
		this.notify('newfloor', this);
		this.floor.push(new Room(this, {
			"key": -1,
			"type": "story",
			"enemies": enemies
		}));
		this.room.enter();
	}

	notify (type, ...data) {

		if ((this.over && type !== "gameover") || (this.win && type !== "victory"))
			return;
		if (this.broadcaster)
			this.broadcaster.notify(type, data);
	}

	subscribe (type, notify) {

		if (this.broadcaster)
			return this.broadcaster.subscribe(type, notify);
		return () => {};
	}

	serialize () {

		return {
			champion: this.champion.serialize(),
			deck: this.deck.map(card => Object.assign({}, card)),
			floors: this.floors.map(floor => floor.map(room => room.serialize())),
			levelingUp: this.levelingUp,
			choosingCard: this.choosingCard,
			droppingCard: this.droppingCard,
			options: this.options ? this.options.map(option => option.key) : null,
			cardChance: this.cardChance,
			eventChance: this.eventChance,
			maxFloor: this.maxFloor,
			storyEvent: this.storyEvent,
			background: this.background,
			audio: this.audio,
			roomsCleared: this.roomsCleared,
			bossesSlain: this.bossesSlain,
			difficultySetting: this.difficultySetting
		}
	}

	static build (src, broadcaster) {

		let run = new Run();
		run.broadcaster = broadcaster;
		run.difficultySetting = src.difficultySetting || "challenge";
		run.champion = Champion.build(run, src.champion);
		run.deck = src.deck.map(card => Object.assign({}, card));
		run.floors = src.floors.map(floor => floor.map(room => Room.build(run, room)));
		run.levelingUp = src.levelingUp;
		run.choosingCard = src.choosingCard;
		run.droppingCard = src.droppingCard;
		run.cardChance = src.cardChance;
		run.eventChance = src.eventChance;
		run.maxFloor = src.maxFloor;
		run.storyEvent = src.storyEvent;
		run.background = src.background;
		run.audio = src.audio;
		run.roomsCleared = src.roomsCleared;
		run.bossesSlain = src.bossesSlain;
		if (src.options)
			run.options = src.options.map(option => Library.getCard(option));
		return run;
	}
}