import React, { Component } from 'react';
import './Champion.css';
import Img from '../../StableImage';

export default class Champion extends Component {

	render () {

		if (!this.props.src)
			return <div className="gg-champion"></div>;

    let src = this.props.src;
    let model = src.key ? src : src.model;

		return(
      <div className="gg-champion">
			 <Img key={model.key} alt="" src={model.img}/>
      </div>
		);
	}
}