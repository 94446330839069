import React, { Component } from 'react';
import './Campfire.css';
import Card from '../../components/cards/Card';
import Library from '../utility/Library';

const champions = Library.champions;

export default class Campfire extends Component {

  render () {

    return (
      <div className="game-campfire">
      {
        this.props.src.choices.map((c,i) => 
            <div key={i}
            className="campfire-choice"
            onClick={e => this.props.onSelect(c)}>
              <Card src={{name: c, img: champions[this.props.src.run.champion.model.key].campfire[c][this.props.src.run.champion.level-1]}}/>
              <div className="campfire-info">
                <div className="game-card-info game-effect">
                {
                  (() => {
                    switch (c) {
                    case "rest": return <span>Recover <span className="campfire-info-hp">{this.props.src.restHealing}</span> HP.</span>;
                    case "explore": return "Add a card to your deck.";
                    case "worship":
                      return <span>
                      {
                        this.props.src.worshipExp ? <span>Gain <span className="campfire-info-exp">{this.props.src.worshipExp}</span> exp.</span> : ""
                      }
                      {
                        this.props.src.worshipCorruption ? <span>Lose <span className="campfire-info-corruption">{this.props.src.worshipCorruption}</span> corruption.</span> : ""
                      }
                      </span>;
                    }
                  })()
                }
                </div>
              </div>
            </div>
        )
      }
      </div>
    );
  }
}

