
export default class Combination {

	constructor (...animations) {

		this.animations = animations;
		this.no = 0;
	}

	get current () { return this.animations[this.no]; }

	start (update) {
		
		if (this.no < this.animations.length) {
			this.current.start(() => {
				this.no++;
				this.start(update);
			});
		} else {
			this.no = 0;
			update();
		}
	}

	get sync () {

		return this.animations.some(a => a.time > 0);
	}
}