import Library from '../utility/Library';
import Entity from './Entity';
import Status from './Status';
import Reader from './blueprint/Reader';

export default class Enemy extends Entity {

	constructor (master, model) {

		super();
		master.register(this, "enemy");
		this.model = Library.getEnemy(model, master.run.difficultySetting);
		this.maxhp = Math.floor(Math.random() * (this.model.hp[1] - this.model.hp[0] + 1)) + this.model.hp[0];
		this.hp = this.maxhp;
		this.block = this.model.block;
		//this.drops = this.model.drops.map(drop => Object.assign({}, drop));
		master.notify('newenemy', this, master, model);

		if (this.model.patterns) {
			this.patterns = this.model.patterns.map(pattern => Object.assign({}, pattern));
			this.patterns.forEach((pattern,i) => {
				this.computecode = i;
				pattern.blueprint = Reader.read(pattern.blueprint, this);
				delete this.computecode;
				let e = this.events[0];
				pattern.act = () => e();
				delete this.events;
			});
		}
	}

	init () {

		if (this.model.states)
			this.model.states.forEach(state => this.apply(state.key, state.value));
	}

	get key () { return this.id.no; }

	get alive () { return this.hp > 0 }
	get dead () { return this.hp <= 0 }

	get intent () { return this.nointent !== null && this.nointent !== undefined ? this.patterns[this.nointent] : null; }
	get size () { return this.model.size; }

	die () {

		super.die();
		this.master.notify('enemydie', this);
		//this.drops.forEach(drop => this.master.addLoot(drop));
		if (this.master.enemies.filter(e => e.alive).length <= 0)
			this.master.win();
	}

	getRandomIndex(arr) {
	    // Créer le tableau cumulatif des probabilités
	    const cumulativeSum = [];
	    let sum = 0;
	    for (let i = 0; i < arr.length; i++) {
	        sum += arr[i];
	        cumulativeSum.push(sum);
	    }

	    // Générer un nombre aléatoire entre 0 et la somme totale des valeurs
	    const randomValue = Math.random() * sum;

	    // Trouver l'index correspondant
	    let i = 0;
	    while (cumulativeSum[i] < randomValue)
	    	i++;

	    return i;
	}

	haveIntent () {

		if (!this.patterns)
			return null;

		this.nointent = this.patterns.findIndex(p => p.cycle && this.master.turncount % p.cycle === 0);
		if (this.nointent === -1)
			this.nointent = this.getRandomIndex(this.previousintent !== null && this.previousintent !== undefined ? this.patterns[this.previousintent].next : this.model.starter);
		this.previousintent = this.nointent;
		this.master.notify('intent', this, this.nointent);
		return this.intent;
	}

	act () {

		if (this.intent) {
			this.master.notify('enemyact.before', this, this.intent.damage ? "attack" : "status");
			this.intent.act();
			this.master.notify('enemyact', this, this.intent.damage ? "attack" : "status");
			delete this.nointent;
		}
	}

	serialize () {

		return {
			key: this.key,
			model: this.model.key,
			maxhp: this.maxhp,
			hp: this.hp,
			block: this.block,
			//drops: this.drops.map(drop => Object.assign({}, drop)),
			statuses: this.statuses.map(status => status.serialize()),
			nointent: this.nointent,
			previousintent: this.previousintent
		}
	}

	static build (master, src) {

		let enemy = new Enemy(master, src.model);
		enemy.maxhp = src.maxhp;
		enemy.hp = src.hp;
		enemy.block = src.block;
		//enemy.drops = src.drops.map(drop => Object.assign({}, drop));
		enemy.statuses = src.statuses.map(status => Status.build(master, enemy, status));
		enemy.nointent = src.nointent;
		enemy.previousintent = src.previousintent;
		return enemy;
	}
}