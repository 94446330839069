import Animation from './Animation';

export default class NewIntent extends Animation {

	constructor (master, entity) {

		super(master, 0);
		this.entity = entity;
		//this.loadAudio("draw");
	}

	run () {

		var el = document.querySelector("#game-entity-" + this.entity + " .game-entity-main");
		if (el) {
			//el.classList.add("intent-anim");
			setTimeout(() => el.classList.remove("intent-anim"), 800);
		}
	}
}