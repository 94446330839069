import Bloc from './Bloc.js';
import Types from './Types.js';

export default class StatusValue extends Bloc {

	constructor (src, ctx) {

		super("statusvalue", src, ctx);
		this.f = (src, ins) => {
			let statuses = ins[0].statuses.filter(status => status.model.key === ins[1]);
			return statuses.length > 0 ? [statuses[0].value] : [0];
		}
		this.types = [Types.entity, Types.int];
	}
}