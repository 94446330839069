function isOlderOrEqual(version1, version2) {
    const v1Parts = version1.split('.').map(Number);
    const v2Parts = version2.split('.').map(Number);

    const maxLength = Math.max(v1Parts.length, v2Parts.length);

    for (let i = 0; i < maxLength; i++) {
        const v1 = v1Parts[i] || 0;
        const v2 = v2Parts[i] || 0;

        if (v1 < v2) {
            return true;
        } else if (v1 > v2) {
            return false;
        }
    }

    return true;
}

export function updateSaveData(savedata) {

	const version = savedata.version;

	if (isOlderOrEqual(version, "0.1")) {
		// Revert progress save bug
		let progress = JSON.parse(savedata.progress)
		Object.keys(progress).forEach(key => progress[key].story = Math.min(1, progress[key].story))
		savedata.progress = JSON.stringify(progress);
	}
}