import Animation from './Animation';
import './Status.css';
import Library from '../../utility/Library';

export default class Status extends Animation {

	constructor (master, entity, status) {

		super(master, 450);
		this.entity = entity;
		this.status = Library.getStatus(status);
		//this.loadAudio("trigger");
	}

	run () {

		var el = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-afflict-status:not(.status-anim)");
		var text = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-afflict-status:not(.status-anim) .game-entity-afflict-status-text");
		var img = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-afflict-status:not(.status-anim) .game-entity-afflict-status-img");
		if (el) {
			el.classList.add("status-anim");
			el.classList.add(this.status.type);
			img.src = '/images/statuses/' + this.status.name + '.png';
			text.textContent = this.status.name;
			setTimeout(() => { el.classList.remove("status-anim"); el.classList.remove(this.status.type); }, 800);
		}
	}
}