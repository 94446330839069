import Bloc from './Bloc.js';
//import Types from './Types.js';

export default class TurnCount extends Bloc {

	constructor (src, ctx) {

		super("turncount", src, ctx);
		this.f = (src, ins) => [src.master.turncount];
		this.types = [];
	}
}