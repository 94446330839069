import React, { Component } from 'react';
import './Story.css';
import Nav from '../../nav/Nav';
import MainButton from '../../buttons/MainButton';
import Link from '../../utility/Link';
import Back from '../../Back';
import StoryText from '../../text/StoryText';
import Library from '../../../scene/utility/Library';
import User from '../../../User';
import Img from '../../../StableImage';

import { read } from '../../../TextManager';

const statuses = Object.values(Library.statuses);
const champions = Object.values(Library.champions);

export default class Story extends Component {

  state = {
    champion: User.championSelect,
    difficulty: User.difficulty
  }
  
  toggleTooltip(f) {

    this.setState({tooltip: this.state.tooltip === f ? null : f})
  }

  computeEffect (text) {

    let splits = [];
    // eslint-disable-next-line
    let texts = text.split(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/);
    // eslint-disable-next-line
    let matches = text.match(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/g);
    if (matches)
      matches.forEach((match,i) => {
        let el = match.slice(1, -1);
        splits.push(<span key={i+"a"}>{ texts[i] }</span>);
        if (match[0] === '{') {
          let key = match.substring(1, match.length - 1)
          splits.push(<span>{Library.cards[key].name}</span>);
          /*let slices = el.split('/');
          let key = slices[0];
          let ntoken = this.state.token.slice(); ntoken.push(Library.cards[key]);
          let repeat = this.current.key && key === this.current.key.toString();
          splits.push(<span onClick={repeat ? () => {} : () => this.setState({tooltip: null, token: ntoken})} key={i} className={"token" + (repeat ? " repeat-token" : "")} id={'effect-' + i}>{ slices.length > 1 ? slices[1] : Library.cards[key].name }</span>);*/
        } else if (match[0] === '[') {
          let slices = el.split('/');
          let keyword = slices[0];
          let status = statuses.filter(s => s.name === keyword)[0];
          splits.push(<span key={i} className="keyword" id={'effect-' + i}><Img key={keyword + "-" + i + "-" + this.state.champion.key} className="keyword-icon" src={"/images/statuses/" + keyword + ".png"} alt=""/>{slices.length > 1 ? slices[1] : status.name}</span>);
          //splits.push(<Tooltip key={i+"t"} className="tooltip" placement="top" isOpen={this.state.tooltip === i} target={"effect-" + i} toggle={() => this.toggleTooltip(i)}>{ status.effect }</Tooltip>);
         
        } else if (match[0] === '\n') {
          splits.push(<br key={i}/>);
        } else if (match[0] === 'D') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i} className="num-effect damage-effect">{ initialvalue }</span>);
        } else if (match[0] === 'B') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i} className="num-effect block-effect">{ initialvalue }</span>);
        }
      });
    splits.push(<span key={"end"}>{ texts[texts.length-1] }</span>);
    return splits;
  }

  embark () {

    User.removeItem("run.save");
    User.removeItem("run.log");
    User.setItem("champion.select", this.state.champion);
  }

  setDifficulty (difficulty) {

    if (this.state.difficulty === difficulty)
      return;
    User.difficulty = difficulty;
    this.setState({difficulty});
  }

  render () {

    let champion = Library.getChampion(this.state.champion);

    let locked = champion.premium && !User.premium;

    return (
      <div className="main-page story-page">
        <Nav/>
        <div className="main">
          <div className={"story-background" + (locked ? " locked" : "") }>
            <Img key={champion.key} retries={3} delay={5000} src={champion.fullart}/>
          </div>
          <div className="story-champion">
            <div className="story-champion-name game-effect"><Img key={champion.key} src={"/images/level/" + champion.name + ".png"} className="keyword-icon"/>{champion.name}</div>
            <div className="story-champion-effect game-effect">{this.computeEffect(champion.passive.effect)}</div>
            <div className="story-champion-quote">{champion.quote}</div>
            { locked ?
              <div className="story-patreon">
                <div>Available in a future update</div>
                <a href="https://www.patreon.com/neinalen/membership" target="_blank">Get now on Patreon</a>
              </div> :
              <Link to="/game">
                <div className="story-embark" onClick={() => this.embark()}>Embark</div>
              </Link> 
            }
            { !locked &&
              <div className="difficulty-switch">
                <div onClick={() => this.setDifficulty("casual")} className={"difficulty-option casual-option " + (this.state.difficulty === "casual" ? "active" : "")}>Casual</div>
                <div onClick={() => this.setDifficulty("challenge")} className={"difficulty-option challenge-option " + (this.state.difficulty === "challenge" ? "active" : "")}>Challenge</div>
              </div>
            }
            <div onClick={() => this.setState({champion: this.state.champion == 1 ? champions.length : this.state.champion - 1})} className="cardbox-arrow cardbox-arrow-left"><Img alt="" src="/images/arrowhead.png"/></div>
            <div onClick={() => this.setState({champion: this.state.champion % champions.length + 1})} className="cardbox-arrow cardbox-arrow-right"><Img alt="" src="/images/arrowhead.png"/></div>
          </div>
        </div>
        <Back to="/play"/>
      </div>
    );
  }
}

