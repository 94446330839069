import Bloc from './Bloc.js';
import Types from './Types.js';
import Card from '../Card.js';

export default class Morph extends Bloc {

	constructor (src, ctx) {

		super("morph", src, ctx, true);
		this.f = (src, ins) => {
			let location = ins[0].location;
			if (location.type === "court")
				location = ins[0].master.discard;
			ins[0].exile();
			let gen = new Card(src.master, ins[1]);
			location.addCard(gen);
			return [];
		};
		this.types = [Types.card, Types.model];
	}
}