import React, { Component } from 'react';

class StableImage extends Component {

    constructor(props) {
        super(props);

        // Initial state
        this.state = {
            currentSrc: props.src,
            attempt: 0,
        };

        this.handleImageError = this.handleImageError.bind(this);
    }

    handleImageError() {
        const { src, retries = 10, delay = 1000 } = this.props;
        const { attempt } = this.state;

        if (attempt < retries) {
            this.setState(
                (prevState) => ({
                    attempt: prevState.attempt + 1,
                    currentSrc: `${src}?retry=${prevState.attempt + 1}`,
                }),
                () => {
                    // Retry after a delay
                    setTimeout(() => {
                        this.forceUpdate(); // Forces a re-render to attempt loading the new src
                    }, delay);
                }
            );
        } else {
            console.error(`Failed to load image ${src} after ${retries} attempts.`);
        }
    }

    render() {
        const { alt, ...restProps } = this.props;
        const { currentSrc } = this.state;

        return (
            <img
                {...restProps}
                src={currentSrc}
                alt={alt}
                onError={this.handleImageError}
                className={this.props.className + " no-select"}
            />
        );
    }
}

export default StableImage;
