import Location from './Location';
import Card from './Card';

export default class Deck extends Location {

	constructor (master) {

		super(master, "deck");
	}

	init (list) {

		this.starting = [];
		list.forEach(id => this.starting.push(id));
		this.starting.forEach(model => {
			if (!model.replicate)
				this.addCard(new Card(this.master, model));
			else
				for (let i = 0; i < model.replicate; i++)
					this.addCard(new Card(this.master, model));
		});
		this.shuffle();
	}

	shuffle() {

	    for (let i = this.cards.length - 1; i > 0; i--) {
	        const j = Math.floor(Math.random() * (i + 1));
	        [this.cards[i], this.cards[j]] = [this.cards[j], this.cards[i]];
	    }
	}

	addCard (card) {

		if (this.hasCard(card))
			return;
		this.cards.splice(Math.floor(Math.random() * (this.count+1)), 0, card);
		if (card.location !== this)
			card.goto(this);
	}

	draw(filter) {

		if (this.cards.length <= 0) {
			if (!filter)
				return;
			return;
		}

		let card = filter ? this.cards.find(filter) : this.cards[0];
		
		return card;
	}
}