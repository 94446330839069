import React, { Component } from 'react';
import './CardDropper.css';
import Card from '../../components/cards/Card';
import Library from '../utility/Library';
import { Tooltip } from 'reactstrap';
import Img from '../../StableImage';

const statuses = Object.values(Library.statuses);

export default class CardDropper extends Component {

  state = {
    info: null,
    tooltip: null
  }

  info (card) {

    if (this.state.info !== card)
      this.setState({info: card})
  }

  computeEffect (text) {

    let splits = [];
    // eslint-disable-next-line
    let texts = text.split(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/);
    // eslint-disable-next-line
    let matches = text.match(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/g);
    if (matches)
      matches.forEach((match,i) => {
        let el = match.slice(1, -1);
        splits.push(<span key={i+"a"}>{ texts[i] }</span>);
        if (match[0] === '{') {
          let key = match.substring(1, match.length - 1)
          splits.push(<span className="token" onClick={() => this.props.onFocus(Library.cards[key])} key={i}>{Library.cards[key].name}</span>);
          /*let slices = el.split('/');
          let key = slices[0];
          let ntoken = this.state.token.slice(); ntoken.push(Library.cards[key]);
          let repeat = this.current.key && key === this.current.key.toString();
          splits.push(<span onClick={repeat ? () => {} : () => this.setState({tooltip: null, token: ntoken})} key={i} className={"token" + (repeat ? " repeat-token" : "")} id={'effect-' + i}>{ slices.length > 1 ? slices[1] : Library.cards[key].name }</span>);*/
        } else if (match[0] === '[') {
          let slices = el.split('/');
          let keyword = slices[0];
          let status = statuses.filter(s => s.name === keyword)[0];
          splits.push(<span key={i} className="keyword" id={'effect-' + i} onClick={() => this.toggleTooltip(i)}>{status.keyword ? "" : <Img key={keyword + '-' + i} className="keyword-icon" src={"/images/statuses/" + keyword + ".png"} alt=""/>}{slices.length > 1 ? slices[1] : status.name}</span>);
          splits.push(<Tooltip key={i+"t"} className="tooltip" placement="top" isOpen={this.state.tooltip === i} target={"effect-" + i} toggle={() => this.toggleTooltip(i)}>{ status.effect }</Tooltip>);
         
        } else if (match[0] === '\n') {
          splits.push(<br key={i}/>);
        } else if (match[0] === 'D') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i} className="num-effect damage-effect">{ initialvalue }</span>);
        } else if (match[0] === 'B') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i} className="num-effect block-effect">{ initialvalue }</span>);
        }
      });
    splits.push(<span key={"end"}>{ texts[texts.length-1] }</span>);
    return splits;
  }

  toggleTooltip (tooltip) {

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  effectLength (effect) {

    let length = effect.length;
    if (length > 85)
      return "verylong";
    else if (length > 60)
      return "long";
    return "regular";
  }

  render () {

    return (
      <div className="card-dropper">
        <div className="lightbox-container">
          <div className="lightbox-inner">
            <div className="card-dropper-inner">
              <div className="card-dropper-info">
                <div className="card-dropper-text">Drop one</div>
                {
                  this.state.info ?
                  <div className="game-card-info">
                    <div className="game-card-info-inner-selector">
                      <div className="game-card-info-type">{this.state.info.type}</div>
                      <div className={"game-card-info-effect game-effect text-" + this.effectLength(this.state.info.effect)}>{this.computeEffect(this.state.info.effect)}</div>
                    </div>
                  </div> : ""
                }
                </div>
                <div className="card-dropper-options">
                {
                  this.props.deck.map((card, i) => { return {card, index:i}}).sort((a, b) => a.card.key - b.card.key).map(sorted =>
                    <div key={sorted.index} className="card-dropper-option" onClick={() => this.props.dropCard(sorted.index)}
                    onMouseMove={() => this.info(sorted.card)}>
                      <div className="card-dropper-option-inner">
                        <Card src={sorted.card}/>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

