import Bloc from './Bloc.js';
import Types from './Types.js';

export default class DissipateStatus extends Bloc {

	constructor (src, ctx) {

		super("dissipatestatus", src, ctx, true);
		this.f = (src, ins) => {
			let statuses = ins[0].statuses.filter(status => status.model.key === ins[1]);
			if (statuses.length > 0)
				statuses[0].dissipate();
			return [];
		};
		this.types = [Types.entity, Types.int];
	}
}