import Bloc from './Bloc.js';
import Types from './Types.js';

export default class IsPlaying extends Bloc {

	constructor (src, ctx) {

		super("isplaying", src, ctx);
		this.f = (src, ins) => [(src.master.turn === "player") === (ins[0].id === "player")];
		this.types = [Types.entity];
	}
}