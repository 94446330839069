import React, { Component } from 'react';
import './Game.css';
import Nav from '../../nav/Nav';
import MainButton from '../../buttons/MainButton';
import Back from '../../Back';
import Deck from '../../cards/Deck';
import Flowers from '../../other/flowers/Flowers';
import StoryText from '../../text/StoryText';
import Scene from '../../../scene/Scene';

import { read } from '../../../TextManager';

export default class Game extends Component {

  render () {

    return (
      <div className="main-page game-page">
        <Nav socials="none"/>
        <div className="main">
          <Scene/>
        </div>
        <Back to="/play"/>
      </div>
    );
  }
}

