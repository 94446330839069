import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Pattern extends Bloc {

	constructor (src, ctx, target) {

		super("pattern", src, ctx, true);
		this.f = (src, ins) => [this];
		this.types = [];
		this.target = target;
		this.trigger = (src, image) => this.execute({src, image});
	}

	setup (owner, image) {

		owner.events = owner.events || [];
		owner.events.push(() => {
			this.out = [this];
			this.trigger(owner, image);
		});
	}
}