import React, { Component } from 'react';
import Flowers from '../other/flowers/Flowers';
import StoryText from '../text/StoryText';
import MainButton from '../buttons/MainButton';
import User from '../../User';
import './PatreonRedirect.css';

export default class PatreonRedirect extends Component {
  state = {
    message: 'Processing Patreon authentication...',
    isSupporter: false,
  };

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const authorizationCode = query.get('code');

    if (authorizationCode) {
      this.exchangeAuthorizationCode(authorizationCode);
    } else {
      this.setState({ message: 'No authorization code found.', finalized: true });
    }
  }

  exchangeAuthorizationCode = async (authorizationCode) => {
    try {
      const response = await fetch(`/.netlify/functions/patreon-auth?code=${authorizationCode}`);
      const data = await response.json();

      if (data.error) {
        this.setState({ message: `Error: ${data.error}` });
      } else {
        if (data.elite) {
          localStorage.setItem("patreon.token", data.token);
          User.unlockPremium();
          this.setState({ message: 'Thank you for your support! You now have access to exclusive features.', finalized: true });
        } else {
          this.setState({ message: 'You need to be an elite supporter or higher to gain access to exclusive features.', finalized: true });
        }
      }
    } catch (error) {
      console.error('Error exchanging authorization code:', error);
      this.setState({ message: 'An unexpected error occured. Please contact the developer.', finalized: true });
    }
  };

  render() {
    const { message, finalized } = this.state;

    return (
      <div className="main-page light patreon-redirect-page">
        <Flowers/>
        <div className="main">
          <StoryText>{message}</StoryText>
          {finalized && (
            <div className="redirect-finalized">
              <MainButton to="/play">Return</MainButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}