import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Value extends Bloc {

	constructor (src, ctx) {

		super("value", src, ctx);
		this.f = (src, ins) => [src.value];
		this.types = [];
	}
}