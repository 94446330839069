import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Timestamp extends Bloc {

	constructor (src, ctx) {

		super("timestamp", src, ctx);
		this.f = (src, ins) => {
			switch (ins[1]) {
			case "newplayerturn": return ["playerturn"];
			case "endplayerturn": return ["endplayerturn"];
			case "newenemyturn": return ["enemyturn"];
			case "endenemyturn": return ["endenemyturn"];
			case "newturn": return [ins[0].id === "player" ? "playerturn" : "enemyturn"];
			case "endturn": return [ins[0].id === "player" ? "endplayerturn" : "endenemyturn"];
			case "everynewturn": return [["playerturn", "enemyturn"]];
			case "everyendturn": return [["endplayerturn", "endenemyturn"]];
			default: return [ins[1]];
			}
		};
		this.types = [Types.entity, Types.timestamp];
	}
}