import React, { Component } from 'react';
import './Socials.css';
import Lightbox from '../utility/Lightbox';
import MainButton from '../buttons/MainButton';

import { read } from '../../TextManager';

export default class Socials extends Component {

  state = {
    status: null
  }

  render () {

    return (
      <div className="nav-item nav-socials">
        <Lightbox className="small" open={this.state.status === "socials"} onClose={() => this.setState({status: null})}>
          <div className="options-box socials-box">
            <h1>Socials</h1>
            <MainButton color="patreon" onClick={() => window.open("https://www.patreon.com/NeiNalen", '_blank')}>Patreon</MainButton>
            <MainButton color="discord" onClick={() => window.open("https://discord.gg/dh4Cj4BJt8", '_blank')}>Discord</MainButton>
            <MainButton color="itchio" onClick={() => window.open("https://neinalen.itch.io/maze-of-ambition", '_blank')}>Itch.io</MainButton>
            <MainButton color="twitter" onClick={() => window.open("https://x.com/neinalen", '_blank')}>Twitter</MainButton>
          </div>
        </Lightbox>
        <div onClick={() => this.setState({status: "socials"})} className="socials-icon"/>
      </div>
    );
  }
}

