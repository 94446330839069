import React, { Component } from 'react';
import './Nav.css';
import Options from './Options';
import Socials from './Socials';
import Credit from './Credit';

//import { read } from '../../TextManager';

export default class Nav extends Component {

  render () {

    return (
      <nav>
        { this.props.socials === "none" ? "" : <Socials/> }
        <Options concede={this.props.concede}/>
      </nav>
    );
  }
}

