import React, { Component } from 'react';
import './Gallery.css';
import Nav from '../../nav/Nav';
import Back from '../../Back';
import User from '../../../User';
import CutsceneManager from '../../../scene/CutsceneManager';
import Cutscene from '../../../scene/view/Cutscene';
import allcutscenes from '../../../library/cutscenes.json';

import { read } from '../../../TextManager';

export default class Gallery extends Component {

  cutsceneManager = new CutsceneManager(null, c => this.setState({cutscene: c ? Object.assign({}, this.state.cutscene, c) : null}))

  state = {
    cutscene: null
  }

  render () {

    let cutscenes = Object.values(allcutscenes).filter(cutscene => User.hasSeen(cutscene.key)).sort((a, b) => a.key - b.key);

    return (
      <div className="main-page gallery-page">
        <Nav/>
        <div className="main">
          <div className="gallery">
            {
              cutscenes.map(cutscene =>
                <div key={cutscene.key} className="gallery-cutscene"
                onClick={() => {
                  this.cutsceneManager.callback = () => this.setState({cutscene: null})
                  this.cutsceneManager.start(cutscene);
                }
                }>
                { cutscene.name }
                </div>
              )
            }
          </div>
          {
            this.state.cutscene ?
            <Cutscene back={() => this.cutsceneManager.back()} skip={() => this.cutsceneManager.skip()} continue={() => this.cutsceneManager.continue()} src={this.state.cutscene}/> : ""
          }
        </div>
        <Back to="/play"/>
      </div>
    );
  }
}

