import Bloc from './Bloc.js';
import Types from './Types.js';

export default class BreakChampion extends Bloc {

	constructor (src, ctx) {

		super("breakchampion", src, ctx);
		this.f = (src, ins) => [src.master.run.champion.level, src.master.run.champion.exp, src.master.run.champion.hp, src.master.run.champion.maxhp];
		this.types = [];
	}
}