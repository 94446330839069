import Bloc from './Bloc.js';
import Types from './Types.js';

export default class GetBlock extends Bloc {

	constructor (src, ctx) {

		super("getblock", src, ctx);
		this.f = (src, ins) => [ins[0].block];
		this.types = [Types.entity];
	}
}