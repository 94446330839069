import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Compute extends Bloc {

	constructor (src, ctx, target) {

		super("compute", src, ctx, true);
		this.f = (src, ins) => [this];
		this.types = [Types.int, Types.value];
	}

	setup (owner, image) {

		let code = owner.computecode;
		let previousCompute = owner.compute ? owner.compute : (c => { return { type: null, value: 0 } })
		owner.compute = c => {
			if (code === undefined || code === null || c === code)
				return { type: this.in[1](), value: this.in[0]() };
			return previousCompute(c);
		}
	}
}