import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Damage extends Bloc {

	constructor (src, ctx) {

		super("damage", src, ctx, true);
		this.f = (src, ins) => {
			let result = ins[0].damage(ins[3] === false ? ins[2] : ins[0].modifyDamageIn(ins[2], ins[1]), ins[1], ins[3] !== false);
			return [result.dmg, ins[0].dead, 0];
		};
		this.types = [Types.entity, Types.entity, Types.int, Types.bool];
	}
}