import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Apply extends Bloc {

	constructor (src, ctx) {

		super("applystatus", src, ctx, true);
		this.f = (src, ins) => {
			ins[0].apply(ins[1], ins[2]);
			return [];
		};
		this.types = [Types.entity, Types.int, Types.int];
	}
}