import React, { Component } from 'react';
import './Champion.css';
import Entity from './Entity';

export default class Champion extends Component {

  render () {

    return (
      <div className="game-champion">
        <Entity onSelect={this.props.onSelect} src={this.props.src}/>
      </div>
    );
  }
}

