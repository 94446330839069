import Animation from './Animation';
import './Attack.css';

export default class Attack extends Animation {

	constructor (master, entity) {

		super(master, 100);
		this.entity = entity;
		//this.loadAudio("draw");
	}

	run () {

		var el = document.querySelector("#game-entity-" + this.entity + " .game-entity-main");
		if (el) {
			el.classList.add("attack-anim");
			setTimeout(() => el.classList.remove("attack-anim"), 600);
		}
	}
}