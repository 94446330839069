import Library from '../utility/Library';
import User from '../../User';

export default class Champion {

	constructor (run, model) {

		this.run = run;
		this.model = Library.getChampion(model);
		this.hp = this.model.hp * (this.run.casual ? (5/4) : 1);
		this.maxhp = this.model.hp * (this.run.casual ? (5/4) : 1);
		this.exp = 0;
		this.level = 1;
		this.maxLevel = Math.min(2, User.progress[this.model.key].level);
		this.gold = 0;
		this.corruption = 0;
		this.relics = [];
	}

	get id () { return "champion"; }

	get threshold () {

		return (this.run.casual ? this.model.thresholdsCasual : this.model.thresholds)[this.level-1];
	}

	checkLevelUp () {

		if (this.exp < this.threshold)
			return false;
		if (this.level >= this.maxLevel)
			return false;

		this.loseExp(this.level + 1 === this.maxLevel ? this.exp : this.threshold);
		this.levelUp();

		return true;
	}

	levelUp () {

		this.level++;
		let options = this.run.levelUp();
		this.gainMaxHp(this.level * 20  * (this.run.casual ? (5/4) : 1));
		this.run.notify('levelup', this, this.level, options);
	}

	gainExp (amt) {

		if (this.level >= this.maxLevel)
			return;
		this.exp += amt;
		this.run.notify('gainexp', this, amt);
	}

	loseExp (amt) {

		this.exp -= amt;
		this.run.notify('loseexp', this, amt);
	}

	gainGold (amt) {

		this.gold += amt;
		this.run.notify('gaingold', this, amt);
	}

	loseGold (amt) {

		amt = Math.max(0, this.gold - amt);
		if (amt <= 0)
			return;

		this.gold -= amt;
		this.run.notify('losegold', this, amt);
	}

	gainCorruption (amt) {

		this.corruption += amt;
		this.run.notify('gaincorruption', this, amt);
	}

	loseCorruption (amt) {

		amt = Math.max(0, this.corruption - amt);
		if (amt <= 0)
			return;

		this.corruption -= amt;
		this.run.notify('losecorruption', this, amt);
	}

	heal (amt) {

		amt = Math.min(amt, this.maxhp - this.hp);
		
		if (!amt || amt < 0)
			return;

		this.hp += amt;
		this.run.notify('lifegain', this, amt);
	}

	loseLife (amt) {

		if (!amt || amt < 0)
			return;

		this.hp -= amt;
		this.run.notify('lifeloss', this, null, amt);
	}

	gainMaxHp (amt, heal=true) {

		this.maxhp += amt;
		if (heal)
			this.hp += amt;
		this.run.notify('gainmaxhp', this, amt,heal);
	}

	loseMaxHp (amt) {

		this.maxhp -= amt;
		this.hp = Math.min(this.hp, this.maxhp)
		this.run.notify('losemaxhp', this, amt);
	}

	newRelic (relic) {

		this.relics.push(relic);

		if (relic.pickup) {
			if (relic.pickup.hp)
				this.heal(relic.pickup.hp);
			if (relic.pickup.maxhp)
				this.gainMaxHp(relic.pickup.maxhp);
		}

		this.run.notify('relic', this, relic);
	}

	serialize () {

		return {
			model: this.model.key,
			maxhp: this.maxhp,
			hp: this.hp,
			exp: this.exp,
			level: this.level,
			maxLevel: this.maxLevel,
			gold: this.gold,
			corruption: this.corruption,
			relics: this.relics.map(relic => relic.key)
		}
	}

	static build (run, src) {

		let champion = new Champion(run, src.model);
		champion.maxhp = src.maxhp;
		champion.hp = src.hp;
		champion.exp = src.exp;
		champion.level = src.level;
		champion.maxLevel = src.maxLevel;
		champion.gold = src.gold;
		champion.corruption = src.corruption;
		champion.relics = src.relics.map(relic => Library.getRelic(relic));
		return champion;
	}
}