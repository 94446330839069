import Bloc from './Bloc.js';
import Types from './Types.js';

export default class CheckEntity extends Bloc {

	constructor (src, ctx) {

		super("checkentity", src, ctx);
		this.f = (src, ins) => {
			let entity = ins[0];
			if (ins[0] && ins[0].type && ins[0].no !== undefined)
				entity = src.master.find(ins[0]);
			return [entity !== null && entity !== undefined ? (!ins[1] || ins[1](entity)) : false, entity !== null && entity !== undefined];
		}
		this.types = [Types.entity, Types.entityfilter];
	}
}