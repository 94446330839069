import Bloc from './Bloc.js';
import Trigger from './Trigger.js';
import Data from './Data.js';

import Play from './Play.js';
import Pattern from './Pattern.js';
import Listener from './Listener.js';
import Compute from './Compute.js';

import Draw from './Draw.js';
import Conjure from './Conjure.js';
import Shuffle from './Shuffle.js';
import ShuffleDiscard from './ShuffleDiscard.js';
import Discard from './Discard.js';
import Exile from './Exile.js';
import Damage from './Damage.js';
import Block from './Block.js';
import LoseBlock from './LoseBlock.js';
import LoseLife from './LoseLife.js';
import Heal from './Heal.js';
import Apply from './Apply.js';
import ChangeMana from './ChangeMana.js';
import ShiftMana from './ShiftMana.js';
import AddMana from './AddMana.js';
import UseMana from './UseMana.js';
import GainGold from './GainGold.js';
import GainCorruption from './GainCorruption.js';
import LoseGold from './LoseGold.js';
import LoseCorruption from './LoseCorruption.js';
import GainExp from './GainExp.js';
import Morph from './Morph.js';
import HandChoice from './HandChoice.js';
import Dissipate from './Dissipate.js';
import DissipateStatus from './DissipateStatus.js';
import AddProperty from './AddProperty.js';
import ExtraTrigger from './ExtraTrigger.js';

import CountCards from './CountCards.js';
import CheckCard from './CheckCard.js';
import CheckEntity from './CheckEntity.js';
import CompareCards from './CompareCards.js';
import CompareEntities from './CompareEntities.js';
import CompareLocations from './CompareLocations.js';
import FilterStats from './FilterStats.js';
import MergeCardFilters from './MergeCardFilters.js';
import MergeEntityFilters from './MergeEntityFilters.js';

import Timestamp from './Timestamp.js';
import Value from './Value.js';
import Mana from './Mana.js';
import TurnCount from './TurnCount.js';
import StatusValue from './StatusValue.js';
import GetBlock from './GetBlock.js';
import IsPlaying from './IsPlaying.js';
import Analyse from './Analyse.js';
import RandomInt from './RandomInt.js';

import BreakCard from './BreakCard.js';
import BreakChampion from './BreakChampion.js';

import FindCard from './FindCard.js';
import FindEnemy from './FindEnemy.js';

import StoreInteger from './StoreInteger.js';
import IntVariable from './IntVariable.js';

import If from './If.js';
import Loop from './Loop.js';
import ForEachCard from './ForEachCard.js';
import ForEachEnemy from './ForEachEnemy.js';

import Plus from './Plus.js';
import Minus from './Minus.js';
import Times from './Times.js';
import Div from './Div.js';
import Mod from './Mod.js';
import Not from './Not.js';
import And from './And.js';
import Or from './Or.js';
import Xor from './Xor.js';
import Ternary from './Ternary.js';
import Equal from './Equal.js';
import NotEqual from './NotEqual.js';
import Greater from './Greater.js';
import GreaterEqual from './GreaterEqual.js';
import Lesser from './Lesser.js';
import LesserEqual from './LesserEqual.js';
import Max from './Max.js';
import Min from './Min.js';
import Pow from './Pow.js';

import InnerData from './InnerData.js';

import Wait from './Wait.js';

export default class Reader {

	static read (blueprint, card) {

		var ctx = { triggers: [], actions: [], parameters: [] };
		Object.keys(ctx).forEach(key => blueprint[key].forEach((el,i) => {
			var bloc = null;
			switch(el.type) {

			case "play": bloc = new Play(card, ctx); break;
			case "pattern": bloc = new Pattern(card, ctx); break;
			case "listener": bloc = new Listener(card, ctx); break;
			case "compute": bloc = new Compute(card, ctx); break;

			case "draw": bloc = new Draw(card, ctx); break;
			case "conjure": bloc = new Conjure(card, ctx); break;
			case "shuffle": bloc = new Shuffle(card, ctx); break;
			case "shufflediscard": bloc = new ShuffleDiscard(card, ctx); break;
			case "discard": bloc = new Discard(card, ctx); break;
			case "exile": bloc = new Exile(card, ctx); break;
			case "damage": bloc = new Damage(card, ctx); break;
			case "block": bloc = new Block(card, ctx); break;
			case "loseblock": bloc = new LoseBlock(card, ctx); break;
			case "lifeloss": bloc = new LoseLife(card, ctx); break;
			case "heal": bloc = new Heal(card, ctx); break;
			case "applystatus": bloc = new Apply(card, ctx); break;
			case "changemana": bloc = new ChangeMana(card, ctx); break;
			case "shiftmana": bloc = new ShiftMana(card, ctx); break;
			case "addmana": bloc = new AddMana(card, ctx); break;
			case "usemana": bloc = new UseMana(card, ctx); break;
			case "gaingold": bloc = new GainGold(card, ctx); break;
			case "gaincorruption": bloc = new GainCorruption(card, ctx); break;
			case "gainexp": bloc = new GainExp(card, ctx); break;
			case "losegold": bloc = new LoseGold(card, ctx); break;
			case "losecorruption": bloc = new LoseCorruption(card, ctx); break;
			case "morph": bloc = new Morph(card, ctx); break;
			case "handchoice": bloc = new HandChoice(card, ctx); break;
			case "dissipate": bloc = new Dissipate(card, ctx); break;
			case "dissipatestatus": bloc = new DissipateStatus(card, ctx); break;
			case "addproperty": bloc = new AddProperty(card, ctx); break;
			case "extratrigger": bloc = new ExtraTrigger(card, ctx); break;

			case "countcards": bloc = new CountCards(card, ctx); break;
			case "checkcard": bloc = new CheckCard(card, ctx); break;
			case "checkentity": bloc = new CheckEntity(card, ctx); break;
			case "cmpcards": bloc = new CompareCards(card, ctx); break;
			case "cmpentities": bloc = new CompareEntities(card, ctx); break;
			case "cmplocations": bloc = new CompareLocations(card, ctx); break;
			case "filterstats": bloc = new FilterStats(card, ctx); break;
			case "mergecfilters": bloc = new MergeCardFilters(card, ctx); break;
			case "mergeefilters": bloc = new MergeEntityFilters(card, ctx); break;

			case "timestamp": bloc = new Timestamp(card, ctx); break;
			case "value": bloc = new Value(card, ctx); break;
			case "mana": bloc = new Mana(card, ctx); break;
			case "turncount": bloc = new TurnCount(card, ctx); break;
			case "statusvalue": bloc = new StatusValue(card, ctx); break;
			case "getblock": bloc = new GetBlock(card, ctx); break;
			case "isplaying": bloc = new IsPlaying(card, ctx); break;
			case "analyse": bloc = new Analyse(card, ctx); break;
			case "randint": bloc = new RandomInt(card, ctx); break;

			case "breakcard": bloc = new BreakCard(card, ctx); break;
			case "breakchampion": bloc = new BreakChampion(card, ctx); break;

			case "findcard": bloc = new FindCard(card, ctx); break;
			case "findenemy": bloc = new FindEnemy(card, ctx); break;

			case "writeintvar": bloc = new StoreInteger(card, ctx); break;
			case "intvar": bloc = new IntVariable(card, ctx); break;

			case "if": bloc = new If(card, ctx); break;
			case "loop": bloc = new Loop(card, ctx); break;
			case "forcard": bloc = new ForEachCard(card, ctx); break;
			case "forenemy": bloc = new ForEachEnemy(card, ctx); break;

			case "opplus": bloc = new Plus(card, ctx); break;
			case "opminus": bloc = new Minus(card, ctx); break;
			case "optimes": bloc = new Times(card, ctx); break;
			case "opdiv": bloc = new Div(card, ctx); break;
			case "opmod": bloc = new Mod(card, ctx); break;
			case "opnot": bloc = new Not(card, ctx); break;
			case "opand": bloc = new And(card, ctx); break;
			case "opor": bloc = new Or(card, ctx); break;
			case "opxor": bloc = new Xor(card, ctx); break;
			case "opter": bloc = new Ternary(card, ctx); break;
			case "ope": bloc = new Equal(card, ctx); break;
			case "opne": bloc = new NotEqual(card, ctx); break;
			case "opg": bloc = new Greater(card, ctx); break;
			case "opge": bloc = new GreaterEqual(card, ctx); break;
			case "opl": bloc = new Lesser(card, ctx); break;
			case "ople": bloc = new LesserEqual(card, ctx); break;
			case "opmax": bloc = new Max(card, ctx); break;
			case "opmin": bloc = new Min(card, ctx); break;
			case "oppow": bloc = new Pow(card, ctx); break;

			case "innerdata": bloc = new InnerData(card, ctx); break;

			case "wait": bloc = new Wait(card, ctx); break;

			case "play-trigger": bloc = new Trigger(el.type, card, ctx, "cast"); break;
			case "play-data": bloc = new Data(el.type, card, ctx, data => [data[0], data[2], data[1], data[1]]); break;
			case "draw-trigger": bloc = new Trigger(el.type, card, ctx, "draw"); break;
			case "draw-data": bloc = new Data(el.type, card, ctx, data => [data[1]]); break;
			case "damage-trigger": bloc = new Trigger(el.type, card, ctx, "damage"); break;
			case "damage-data": bloc = new Data(el.type, card, ctx, data => [data[0], data[1], data[2], 0, data[3]]); break;
			case "discard-trigger": bloc = new Trigger(el.type, card, ctx, "discard"); break;
			case "discard-data": bloc = new Data(el.type, card, ctx, data => [data[0]]); break;
			case "lifeloss-trigger": bloc = new Trigger(el.type, card, ctx, "lifeloss"); break;
			case "lifeloss-data": bloc = new Data(el.type, card, ctx, data => [data[0], data[1], data[2]]); break;

			default: bloc = new Bloc(el.type, card, ctx); break;
			}
			ctx[key].push(bloc);
		}));
		Object.keys(ctx).forEach(key => blueprint[key].forEach((el, i) => {
			var bloc = ctx[key][i];
			bloc.updateIn(el.in);
		}));
		ctx.blueprint = blueprint;
		blueprint.triggers.forEach((trigger, i) => {
			var bloc = ctx.triggers[i];
			bloc.prepare(trigger, blueprint);
		})
		blueprint.basis.forEach(basis => {
			var bloc = ctx[basis.type][basis.index];
			bloc.setup(card);
		})
	}
}