import Bloc from './Bloc.js';
import Types from './Types.js';

export default class CheckCard extends Bloc {

	constructor (src, ctx) {

		super("checkcard", src, ctx);
		this.f = (src, ins) => {
			let card = ins[0];
			if (ins[0] && ins[0].type && ins[0].no !== undefined)
				card = src.master.find(ins[0]);
			return [card !== null && card !== undefined ? (!ins[1] || ins[1](card)) : false, card !== null && card !== undefined];
		}
		this.types = [Types.card, Types.cardfilter];
	}
}