import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Wait extends Bloc {

	constructor (src, ctx) {

		super("wait", src, ctx, true);
		this.f = (src, ins) => {
			src.master.notify("wait", src.master, ins[0]);
			return [];
		};
		this.types = [Types.int];
	}
}